import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'tokenIndicator'
})
export class TokenIndicatorPipe implements PipeTransform {
  transform(usedToken: number, maxToken: number): string {
    return `${usedToken}/${maxToken} Characters`;
  }
}
