import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DatisSchema } from '@enverus/datis-schema';
import { renderDatis } from '@enverus/datis';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'datis-angular-wrapper',
  imports: [CommonModule],
  templateUrl: './datis-wrapper.component.html',
  standalone: true,
})
export class DatisComponent implements OnInit {
  @ViewChild('datisWrapper', { static: true }) ref!: any;

  @Input() configuration!: DatisSchema;

  ngOnInit() {
    this.renderDatisComponent();
  }
  ngOnChanges() {
    this.renderDatisComponent();
  }

  private async renderDatisComponent() {
    if (this.ref?.nativeElement) {
      try {
        const { emitter } = await renderDatis(
          this.ref.nativeElement,
          this.configuration
        );
        emitter.on('datis-click', {}, (d: any) => {
        });
      } catch (error: any) {
        if (error.details) {
          // console.error('Validation Error: ', error.details);
        }
      }
    }
  }
}
