import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {DataService} from '../../services/data/data.service';
import {LogService} from "../../services/log/log.service";
import {NotificationService} from "../../services/notification/notification.service";
import {SavedFiltersService} from "../../services/savedfilters/savedfilters.service";
import {environment as ENV} from '../../../environments/environment';
import {MatIconModule} from "@angular/material/icon";

@Component({
  selector: 'iv-filter-subscribe-dialog',
  standalone: true,
  imports: [CommonModule, FormsModule, MatButtonModule, MatInputModule, ReactiveFormsModule, MatCheckboxModule, MatRadioModule, MatIconModule],
  templateUrl: './filter-subscribe-dialog.component.html',
  styleUrls: ['./filter-subscribe-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterSubscribeDialogComponent{
  filterSubscribeForm: FormGroup;

  radioOptions  = [
    {label: 'Daily', value: 'daily'},
    {label: 'Weekly', value: 'weekly'},
    {label: 'Monthly', value: 'monthly'},
    {label: 'None', value: 'none'},
    // Add more options as needed
  ];

  error = false;
  success = false;

  keywordMessage: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public filter: any,
              public dialogRef: MatDialogRef<FilterSubscribeDialogComponent>,
              private formBuilder: FormBuilder,
              private dataService: DataService,
              private logService: LogService,
              private notificationService: NotificationService,
              private savedFiltersService: SavedFiltersService
  ) {

    if(filter.hasOwnProperty('settings') && filter.settings.hasOwnProperty('q') && filter.settings.q.length) {
      this.keywordMessage = "Search terms are not yet supported by the notification system.  Email notifications for this filter will be generated without taking them into account.";
    }

    let freq: string = 'none';
    if(filter.hasOwnProperty('alert') && filter.alert.hasOwnProperty('frequency')) {
      switch(filter.alert.frequency) {
        case 'd':
          freq = 'daily';
          break;
        case 'w':
          freq = 'weekly';
          break;
        case 'm':
          freq = 'monthly';
          break;
      }
    }
    this.filterSubscribeForm = this.formBuilder.group({
      selectedOption: freq
    });
  }

  onSubmit() {
    const selectedOption = this.filterSubscribeForm.get('selectedOption')?.value;

    // if no alert exists on filter but we want one, add alert @ prism & update the filter in memory
    if(!this.filter.alert && (selectedOption !== 'none')) {
      let notificationObj = this.savedFiltersService.FilterToNotification(this.filter, selectedOption, this.filter.settings.facetsDiff);
      this.dataService.postAlert(notificationObj).then(async (result: any) => {
        await this.dataService.saveFilter(this.filter.id, this.filter.title, this.filter.settings, result.result.id);
        this.savedFiltersService.addOrUpdateFilterAlert(this.filter.id, result.result);

        this.logService.track("email_alert_added", false,{
          filter_name: this.filter.title,
          filter_selection: this.filter.prettyText,
          email_frequency: selectedOption
        });

      }, err => {
        this.notificationService.open('An error occurred creating the email alert.', '', 5000, 'success');
      });
    }

    // if alert exists on filter but we want 'none', delete alert @ prism & update the filter in memory
    if(this.filter.alert && (selectedOption === 'none')) {
      const alertId = this.filter.alert.id;
      this.dataService.deleteAlert(alertId).then(async (result: any) => {
        await this.dataService.saveFilter(this.filter.id, this.filter.title, this.filter.settings, '');
        this.savedFiltersService.deleteFilterAlert(this.filter.id);

        this.logService.track("email_alert_deleted", false,{
          filter_name: this.filter.title,
          filter_selection: this.filter.prettyText
        });

      }, err => {
        this.notificationService.open('An error occurred deleting the email alert.', '', 5000, 'success');
      });
    }

    // if alert exists but frequency has changed then update @ prism & update the filter in memory
    if(this.filter.alert && (selectedOption !== 'none') && (this.filter.alert.frequency !== selectedOption.charAt(0)) ) {
      const alertId = this.filter.alert.id;
      let notificationObj = this.savedFiltersService.FilterToNotification(this.filter, selectedOption, this.filter.settings.facetsDiff);
      notificationObj.frequency = selectedOption.charAt(0);
      delete notificationObj.type;
      this.dataService.updateAlert(alertId, notificationObj).then(async (result: any) => {
        await this.dataService.saveFilter(this.filter.id, this.filter.title, this.filter.settings, result.result.id);
        this.savedFiltersService.addOrUpdateFilterAlert(this.filter.id, result.result);

        this.logService.track("email_alert_updated", false,{
          filter_name: this.filter.title,
          filter_selection: this.filter.prettyText,
          email_frequency: selectedOption
        });

      }, err => {
        this.notificationService.open('An error occurred updating the email alert.', '', 5000, 'success');
      });
    }

    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
