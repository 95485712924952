<article *ngIf="researchDetailsDoc">
  <h1 id="documentTitle">{{researchDetailsDoc?.title}}</h1>
  <p class="date">
    {{researchDetailsDoc?.series}} | {{researchDetailsDoc?.publishedDate | amDateFormat:'LLL'}} - {{researchDetailsDoc?.publishedDate | amTimeAgo}}<span> | </span><mat-icon class="article-read-time">access_time</mat-icon> <ng-container *ngIf="articleReadTime <= 10"> {{articleReadTime}} min read</ng-container><ng-container *ngIf="articleReadTime > 10">10+ min read</ng-container>
  <p id="authors" *ngIf="researchDetailsDoc?.primaryAuthor?.emailAddress || researchDetailsDoc?.supportingAuthors">
    by:
    <ng-container *ngIf="userService.getResearchUser()">
      <a href="mailto:{{researchDetailsDoc?.primaryAuthor?.emailAddress}}"> {{researchDetailsDoc?.primaryAuthor?.displayName}}</a>
      <ng-container *ngFor="let author of researchDetailsDoc?.supportingAuthors; let first = first; let last = last;">
        <span> | </span><a href="mailto:{{author?.emailAddress}}"> {{author?.displayName}}</a>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!userService.getResearchUser()">
      {{researchDetailsDoc?.primaryAuthor?.displayName}}
      <ng-container *ngFor="let author of researchDetailsDoc?.supportingAuthors; let first = first; let last = last;">
        <span> | </span>{{author?.displayName}}
      </ng-container>
    </ng-container>
  </p>
  <div class="actions">
    <div>
      <button type="button" class="back" (click)="routeToDashboard()" matTooltip="Return to Dashboard">
        <mat-icon svgIcon="back"></mat-icon>
      </button>
      <button type="button" class="download" (click)="downloadDocument()"
              *ngIf="researchDetailsDoc?.files?.documents?.length > 0" matTooltip="Download Primary Report">
        <mat-icon svgIcon="download"></mat-icon>
      </button>
      <button type="button" class="fullframe" (click)="scrollToAttachments()" matTooltip="Go to Metadata / Attachments">
        <mat-icon>attachment</mat-icon>
      </button>
      <ng-container *ngIf="isFavorite(researchDetailsDoc.id)">
        <button (click)="toggleFavorite(researchDetailsDoc)" matTooltip="Remove from Bookmarks">
          <mat-icon>bookmark_remove</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="!isFavorite(researchDetailsDoc.id)">
        <button (click)="toggleFavorite(researchDetailsDoc)" matTooltip="Add to Bookmarks">
          <mat-icon>bookmark_add</mat-icon>
        </button>
      </ng-container>
      <button type="button" [matMenuTriggerFor]="shareMenu" class="share" matTooltip="Share" *ngIf="researchDetailsDoc.collection == 'Live Feed' && researchDetailsDoc.projectKey !== 'GLB'">
        <mat-icon>share</mat-icon>
      </button>
      <mat-menu #shareMenu="matMenu" class="share-menu">
        <button mat-menu-item (click)="shareEmail($event)"><mat-icon>mail</mat-icon>Email</button>
        <button mat-menu-item (click)="shareLinkedIn($event)">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="linkedin"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z"/></svg>
          LinkedIn</button>
      </mat-menu>
      <button type="button" class="fullframe" (click)="toggleFullFrame()" matTooltip="Expand PDF to Browser size" *ngIf="!fullframe && (researchDetailsDoc?.files?.documents?.length > 0 && researchDetailsDoc?.files?.documents[0].mimeType === 'application/pdf')">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button type="button" class="fullframe" (click)="toggleFullFrame()" *ngIf="fullframe">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
    </div>
    <div>
      <a href="research/{{researchDetailsDoc.id}}/interactive" *ngIf="researchDetailsDoc?.files?.interactive?.length > 0" class="interactive">Interactive View</a>
      <ng-container *ngIf="showAnalystButton()">
        <ng-container *ngIf="userService.getResearchUser()">
          <a class="contact-analyst" (click)="contactAnalyst($event)">Analyst Access</a>
        </ng-container>
        <ng-container *ngIf="!userService.getResearchUser()">
          <a class="contact-analyst disabled" matTooltip="** Requires an active Enverus Intelligence | Research subscription, please contact intelligence@enverus.com" matTooltipPosition="below">Analyst Access</a>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <section id="description" *ngIf="researchDetailsDoc?.description">
    <div [innerHTML]="researchDetailsDoc?.description | safeHtml: 'html'"></div>
    <div class="workbooks">
      <p>
        <a href="{{researchDetailsDoc?.coreWorkbookLink}}" class="core" *ngIf="researchDetailsDoc?.coreWorkbookLink" target="_blank" (click)="trackPrismLink('Core')">Enverus Core</a>
        <a href="{{researchDetailsDoc?.dataWorkbookLink}}" class="core-essentials" *ngIf="researchDetailsDoc?.dataWorkbookLink" target="_blank" (click)="trackPrismLink('Foundations')">Enverus Foundations</a>
        <a href="{{researchDetailsDoc?.nextWorkbookLink}}" class="next" *ngIf="researchDetailsDoc?.nextWorkbookLink" target="_blank" (click)="trackPrismLink('Next')">Enverus Next</a>
      </p>
    </div>
  </section>
  <section id="media-player" *ngIf="researchDetailsDoc?.files?.playlist?.length > 0 || videoSrc">
    <iv-media [metadata]="researchDetailsDoc" [media]="researchDetailsDoc?.files?.playlist || videoSrc"></iv-media>
  </section>
  <section id="pdf-preview" #pdfPreview *ngIf="(researchDetailsDoc?.files?.documents?.length > 0 && researchDetailsDoc?.files?.documents[0].mimeType === 'application/pdf') || researchDetailsDoc?.files?.interactive?.length > 0">
    <ng-container *ngIf="researchDetailsDoc?.files?.documents?.length > 0 && researchDetailsDoc?.files?.documents[0].mimeType === 'application/pdf'">
      <iv-inline-loading text="Generating PDF Preview" [loaderStyle]="{background:'transparent'}" [iconStyle]="{width: '30px', height: '30px'}" *ngIf="!document"></iv-inline-loading>
      <iv-pdftron [document]="document" [options]="pdfTronOptions" *ngIf="isBrowser && document" (pdfWordCount)=showPdfReadTime($event)></iv-pdftron>
    </ng-container>
    <p class="disclaimer">© {{date | date:'yyyy' }} Enverus, All Rights Reserved.<br>
      Confidential - Property of Enverus, use governed by terms and conditions found within existing agreement between the parties.
    </p>
  </section>
  <section #endOfPageTarget style="border: 0 !important"></section>
  <section id="attachments" *ngIf="hasAttachments">
    <h2>Attachments
      <button class="upArrow"  *ngIf="showScrollButtonInAttachments" type="button" (click)="scrollToPageTop()" matTooltip="Go to Page Top">
        <mat-icon>north</mat-icon>
      </button>
    </h2>
    <div class="download-queue">
      <button *ngIf="queuedDownloadsCount > 0" (click)="bulkDownloadQueue()" title="{{downloadQueueTooltip}}">Download {{queuedDownloadsCount}} Queued Files</button>
      <span class="download-label">
                  <ng-container *ngIf="queuedDownloadsCount > 0">Un-check</ng-container>
                  <ng-container *ngIf="queuedDownloadsCount == 0">Queue</ng-container> All
                  <ng-container *ngIf="queuedDownloadsCount == 0">for Download</ng-container>
                </span>
      <mat-checkbox (change)="queueAll($event)" [checked]="queuedDownloadsCount > 0"></mat-checkbox>
    </div>
    <ng-container *ngIf="showBulkDownloadMsg">
      <span class="confirmation">Your request is currently being processed. You will receive a link by email when your download is ready.</span>
    </ng-container>
    <ng-container *ngFor="let file of researchDetailsDoc?.files?.attachments">
      <div class="attachmentrow">
        <div class="attachment">
          <a (click)="downloadAttachment(file.filename)">
            <div>
              <strong>
                <ng-container *ngIf="file.name">
                  {{file.name}}
                </ng-container>
                <ng-container *ngIf="!file.name">
                  {{file.filename}}
                </ng-container>
              </strong>
            </div>
          </a>
          <p *ngIf="file.description" [innerHTML]="file.description | safeHtml: 'html'">
            {{file.description}}
          </p>
        </div>
        <div class="checkbox-footer">
          <div class="actions">
            <mat-checkbox [checked]="isQueued(file.filename)" (change)="toggleQueued(file)"></mat-checkbox>
          </div>
        </div>
      </div>
    </ng-container>
  </section>
  <section id="tagging" class="upArrow" *ngIf="hasTags">
    <h2>Tags
      <button *ngIf="showScrollButtonInTags" type="button" (click)="scrollToPageTop()" matTooltip="Go to Page Top">
        <mat-icon>north</mat-icon>
      </button>
    </h2>
    <ng-container *ngFor="let tags of researchDetailsDoc?.tags | mapToIterable">
      <ng-container *ngIf="tags?.value?.value?.length > 0">
        <h3>{{tags?.value?.title}}</h3>
        <p>
          <ng-container *ngFor="let tag of tags?.value?.value; let last = last">{{tag}}<ng-container *ngIf="!last">, </ng-container></ng-container>
        </p>
      </ng-container>
    </ng-container>
  </section>
  <section class="upArrow" *ngIf="!hasAttachments && !hasTags">
    <button type="button" (click)="scrollToPageTop()" matTooltip="Go to Page Top">
      <mat-icon>north</mat-icon>
    </button><br/><br/>
  </section>
  <section id="disclosures" *ngIf="researchDetailsDoc?.disclosures" [innerHTML]="researchDetailsDoc?.disclosures | safeHtml: 'html'">
  </section>
  <p class="meta" *ngIf="researchDetailsDoc?.id && researchDetailsDoc?.key">
    {{researchDetailsDoc?.id}} | {{researchDetailsDoc?.key}}
    <ng-container *ngIf="enverusUser"> | <a href="https://publish.enverus.com/browse/{{researchDetailsDoc?.key}}">Edit</a>
    </ng-container>
  </p>
</article>
