import {Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, ViewChild, ViewEncapsulation} from '@angular/core';
import {CommonModule, isPlatformServer} from '@angular/common';

import {Subscription} from 'rxjs';

import {VgApiService, VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';

import {environment as ENV} from '../../../environments/environment';

import {DataService} from '../../services/data/data.service';
import {LogService} from '../../services/log/log.service';
import {UserService} from '../../services/user/user.service';

import {PdftronComponent} from '../pdftron/pdftron.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';

export interface IMedia {
  title?: string;
  src: string;
  cues?: any;
  type?: string;
  transcript?: string;
}

@Component({
  selector: 'iv-media',
  standalone: true,
  imports: [
    CommonModule,
    VgCoreModule,
    PdftronComponent,
    MatExpansionModule,
    MatIconModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
  ],
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MediaComponent implements OnInit, OnDestroy {
  @Input() metadata: any;
  @Input() media: any;
  @ViewChild('media', {static: true}) mediaEl: any;
  @ViewChild('pdftron', {static: true}) public pdftron: any;

  api: VgApiService | null = null;
  subscriptions: Subscription[] = [];
  playlist: Array<IMedia> = [];
  currentIndex = 0;
  currentItem: IMedia | undefined = undefined;
  document: string | null = '';

  twofive: boolean = false;
  fivezero: boolean = false;
  sevenfive: boolean = false;
  complete: boolean = false;

  isBrowser:boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private userService: UserService,
    private dataService: DataService,
    private logService: LogService
  ) {
  }

  ngOnInit(): void {

    this.isBrowser = !isPlatformServer(this.platformId);

    if (typeof this.media === 'string') {
      this.playlist = [{
        src: this.media,
        type: 'video/mp4'
      }];
      this.currentItem = this.playlist[this.currentIndex];
    } else {
      const customPlaylist = this.media.find((x: any) => x.filename.includes('playlist.json'));
      if (customPlaylist) {
        this.dataService.getMediaPlaylist(customPlaylist.path)
          .subscribe(
            (data) => {
              data.forEach((m: any) => {
                if (!m.src.includes('http')) {
                  m.src = this.media.find((x: any) => x.filename === m.src).path;
                }
                if (m.transcript && !m.transcript.includes('http')) {
                  m.transcript = this.media.find((x: any) => x.filename === m.transcript).path;
                }
                if (m.cues && m.cues.length > 0) {
                  m.cues.forEach((cue: any) => {
                    if (!cue.src.includes('http')) {
                      cue.src = this.media.find((x: any) => x.filename === cue.src).path;
                    }
                  });
                }
              });
              this.playlist = data;
              this.currentItem = this.playlist[this.currentIndex];

            } // success path
            //error => this.error = error // error path
          );
      } else {
        let playlist: any[] = [];
        let playlistItem: any = {};
        this.media.forEach((m: any) => {
          if (m.mimeType.includes('audio/mp3') || m.mimeType.includes('audio/mpeg') || m.mimeType.includes('video/mp4')) {
            let file = m.filename.split('.').slice(0, -1).join('.');
            playlistItem.title = file.replace(/-/g, ' ').replace(/_/g, ' ');
            playlistItem.src = m.path;
            playlistItem.type = m.mimeType;
            let transcript = this.media.find((x: any) => x.filename === file + '.pdf');
            let cue = this.media.find((x: any) => x.filename === file + '.vtt');
            let preview = this.media.find((x: any) => x.filename === file + '.png');
            if (m.mimeType.includes('video/mp4')) {
              if (preview) {
                playlistItem.preview = preview.path;
              } else {
                playlistItem.preview = '/assets/share/SharedStory_1.png';
              }
            } else {
              playlistItem.audio = true;
            }
            if (cue) {
              playlistItem.cues = [{
                kind: 'subtitles',
                label: 'English',
                lang: 'en',
                src: cue.path
              }];
            } else {
              playlistItem.cues = [];
            }
            if (transcript) {
              playlistItem.transcript = transcript.path;
            }
            playlist.push(playlistItem);
          }
        });
        this.playlist = playlist;
        this.currentItem = this.playlist[this.currentIndex];
      }
    }
  }

  onPlayerReady(api: VgApiService) {
    this.api = api;
    this.subscriptions.push(this.api.getDefaultMedia().subscriptions.loadedMetadata.subscribe(this.mediaLoaded.bind(this)));
    this.subscriptions.push(this.api.getDefaultMedia().subscriptions.ended.subscribe(this.nextMedia.bind(this)));
    this.subscriptions.push(this.api.getDefaultMedia().subscriptions.play.subscribe(this.mediaPlay.bind(this)));
    this.subscriptions.push(this.api.getDefaultMedia().subscriptions.timeUpdate.subscribe(this.mediaTimeLine.bind(this)));
    this.subscriptions.push(this.api.getDefaultMedia().subscriptions.error.subscribe(this.mediaError.bind(this)));
  }

  mediaLoaded() {
    // Fix for broken videogular track list updates
    let trackListEl = document.getElementsByClassName('trackSelector')[0];
    let trackList = [];
    if (trackListEl && this.mediaEl.nativeElement.textTracks.length > 0) {
      trackList.push(`<option value="null">Off</option>`);
      for (let i = 0; i < this.mediaEl.nativeElement.textTracks.length; i++) {
        const track = this.mediaEl.nativeElement.textTracks[i];
        trackList.push(`<option value="${track.language}">${track.label}</option>`);
      }
      trackListEl.innerHTML = trackList.join('');
    }

    if (this.currentItem && this.currentItem.transcript) {
      this.document = this.currentItem.transcript;
    } else {
      this.document = null;
    }

    this.twofive = false;
    this.fivezero = false;
    this.sevenfive = false;
    this.complete = false;
  }

  mediaError($event: any) {
  }

  mediaPlay() {
    if (this.currentItem) {
      this.logService.article = this.metadata;
    }
    this.logService.track('video_played', true, {
      video_filename: this.currentItem ? this.getFilename(this.currentItem.src) : undefined
    });

  }

  mediaTimeLine() {
    if (this.api) {
      const payload = this.getMetricPayload();
      let percentage = (this.api.currentTime / this.api.duration) * 100;

      switch (true) {
        case (percentage > 25 && !this.twofive):
          this.twofive = true;

          this.logService.track('video_progress', true, {
            video_filename: this.currentItem ? this.getFilename(this.currentItem.src) : undefined,
            video_progress: 25
          });

          break;
        case (percentage > 50 && !this.fivezero):
          this.fivezero = true;

          this.logService.track('video_progress', true, {
            video_filename: this.currentItem ? this.getFilename(this.currentItem.src) : undefined,
            video_progress: 50
          });

          break;
        case (percentage > 75 && !this.sevenfive):
          this.sevenfive = true;

          this.logService.track('video_progress', true, {
            video_filename: this.currentItem ? this.getFilename(this.currentItem.src) : undefined,
            video_progress: 75
          });

          break;
        case (percentage >= 98 && !this.complete):
          this.complete = true;

          this.logService.track('video_progress', true, {
            video_filename: this.currentItem ? this.getFilename(this.currentItem.src) : undefined,
            video_progress: 100
          });

          break;
        default:
      }
    }
  }

  nextMedia() {
    this.currentIndex++;

    if (this.currentIndex === this.playlist.length) {
      this.currentIndex = 0;
    }

    this.currentItem = this.playlist[this.currentIndex];
  }

  getMetricPayload() {
    let payload = null;
    if (this.currentItem) {
      payload = {
        'meta.research.id': `${this.metadata.id} | ${this.metadata.key}`,
        'meta.research.title': `${this.metadata.title}`,
        'meta.research.intelligenceType': `${this.metadata.intelligenceType}`,
        'meta.research.type': `${this.metadata.researchType}`,
        'meta.research.series': `${this.metadata.series}`,
        'meta.research.publishDate': `${this.metadata.publishedDate}`,
        'meta.research.video.filename': this.getFilename(this.currentItem.src)
      };
    }
    return payload;
  }

  public getFilename(src: string) {
    // @ts-ignore
    return src.split('/').pop().split('#')[0].split('?')[0];
  }

  onClickPlaylistItem(item: IMedia, index: number) {
    this.currentIndex = index;
    this.currentItem = item;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.api = null;
  }

}
