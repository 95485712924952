import {Component, OnDestroy, Renderer2, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {MatMenuModule} from '@angular/material/menu';
import {IvauthService} from '../../services/ivauth/ivauth.service';
import {UserService} from '../../services/user/user.service';
import {RouterModule} from '@angular/router';
import {LogService} from '../../services/log/log.service';
import {FeedbackComponent} from '../../dialogs/feedback/feedback/feedback.component';
import {AuthService} from '@auth0/auth0-angular';

declare let window: any;

@Component({
  selector: 'iv-navbar',
  standalone: true,
  imports: [CommonModule, MatMenuModule, RouterModule],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnDestroy {
  isAuthenticated$ = this.auth.isAuthenticated$;
  menuopen: boolean = false;
  profileSubscription: Subscription;
  profile: any;
  zoomBotOpen: boolean = false;
  showHelp: boolean = false; // this is to control showing the icon when an adblocker is blocking it.

  constructor(public ivauth: IvauthService, private userService: UserService, private dialog: MatDialog, private logService: LogService, private renderer: Renderer2, private auth: AuthService) {
    this.profile = this.userService.getUserProfile();
    this.profileSubscription = this.userService.userProfile.subscribe(profile => {
      this.profile = profile;
    });

    if (window) {
      window.addEventListener('zoomCampaignSdk:ready', () => {
        this.renderer.addClass(this.renderer.selectRootElement('iv-navbar', true), 'zoombot');
        this.showHelp = true;
      });
    }
  }

  logPendoEvent(event: string) {

    if (event === 'Gallery') {
      this.logService.track('gallery_logo_viewed', false, {});
    } else {
      if (event === 'Dashboard') {
        this.logService.track('intelligence_logo_viewed', false, {});
      }
    }

    this.logService.logPendo(`Enverus Logo - ${event}`, {});
  }

  showFeedback(actions?: any) {
    //window['rsResearchFeedback']();

    let dialogRef = this.dialog.open(FeedbackComponent, {
      panelClass: 'iv-feedback',
      disableClose: false,
      autoFocus: true,
      width: '600px',
      height: 'auto',
      data: actions
    });
  }

  showZoomBot() {
    if (window && window.zoomCampaignSdk) {
      window.zoomCampaignSdk.on('open', () => {
        this.zoomBotOpen = true;
      });
      window.zoomCampaignSdk.on('close', () => {
        this.zoomBotOpen = false;
      });
      if (!this.zoomBotOpen) {
        window.zoomCampaignSdk.open();
      } else {
        window.zoomCampaignSdk.close();
      }
    }
  }

  showEmailOptin(actions?: any) {
    //window['rsResearchFeedback']();

    // let dialogRef = this.dialog.open(EmailOptinComponent, {
    //   panelClass: 'rsv-email-optin-dialog',
    //   disableClose: false,
    //   autoFocus: true,
    //   width: '600px',
    //   height: 'auto',
    //   data: actions
    // });
  }

  ngOnDestroy() {
    this.profileSubscription.unsubscribe();
  }

}
