import {ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';

import {Subscription} from "rxjs";

import {Constants} from "../../constants/constants";
import {AzssError} from "../../interface/azss";

import {AzureSearchService} from "../../services/azuresearch/azuresearch.service";
import {DataService} from "../../services/data/data.service";
import {SearchService} from "../../services/search/search.service";
import {SharedService} from "../../services/shared/shared.service";
import {UserService} from '../../services/user/user.service';

type InputSubs = {
  key: string;
  name: string;
  active: boolean;
  filter: string;
};

type InputACLs = {
  id: string;
  name: string;
  azureSearchFilter: string;
  filters: {
    acls: {
      values: string[];
    };
  };
  description: string;
  active: boolean;
};

type OutputACLs = {
  id: string;
  name: string;
  values: string[];
};

@Component({
  selector: 'iv-subscription-filter',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './subscription-filter.component.html',
  styleUrls: ['./subscription-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubscriptionFilterComponent implements OnDestroy, OnInit {
  @Input() pane: string | undefined;
  @Input() isGlobal: boolean | undefined;

  store: any;  // AzssStore;
  storeAzssSubscription: Subscription = Subscription.EMPTY;

  private userPermissions: any = null;

  constructor(
    public azureSearchService: AzureSearchService,
    public dataService: DataService,
    public searchService: SearchService,
    private sharedService: SharedService,
    private userService: UserService,
  ) {
    if(this.isGlobal === undefined) {
      this.isGlobal = false;
    }
  }

  async ngOnInit() {

    switch(this.pane) {
      case 'Reports':
        this.storeAzssSubscription = this.searchService.reportsAzss$.subscribe((data: any) => {
          this.store = data;
        });
        break;
      case 'Livefeed':
        this.storeAzssSubscription = this.searchService.livefeedAzss$.subscribe((data: any) => {
          this.store = data;
        });
        break;
      case 'Chatbot':
        this.storeAzssSubscription = this.searchService.chatbotAzss$.subscribe((data: any) => {
          this.store = data;
        });
        break;
    }

    if((this.store !== undefined) && (this.store.collection === Constants.ChatbotFiltersStores[0])) {
      this.userPermissions = this.userService.getUserPermissions().permissions;

      // user selected subscriptions (for use by IA client)
      const activeKeys: string[] = this.getActiveKeys(this.azureSearchService.get_subscription_filters_filters(this.store.collection));
      let payload: any[] = this.filterActiveItems(this.sharedService.getSubscriptionsUserACLs(), activeKeys);
      this.sharedService.updateChatbotSelectedFilters(payload);
    }

  }

  private getActiveKeys(input: InputSubs[]): string[] {
    return input
      .filter(item => item.active)
      .map(item => item.key);
  }

  getValuesById(subObjects: InputACLs[], id: string): string[] | undefined {
    const foundObject = subObjects.find(obj => obj.id === id);
    return foundObject?.filters.acls.values;
  }

  private filterActiveItems(input: InputACLs[], activeKeys: string[]): OutputACLs[] {

    if(activeKeys[0] !== 'my-subscriptions') {

      return input
        .filter(item => activeKeys.includes(item.id))
        .map(item => ({
          id: item.id,
          name: item.name,
          values: item.filters.acls.values,
        }));

    } else {

      let acls = this.getValuesById(input, 'my-subscriptions');
      let seenIds = new Set<string>();

      return input
        .filter(item => {
          // Exclude items with id 'my-subscriptions'
          if (item.id === "my-subscriptions") {
            return false;
          }

          // Check if the item contains at least one matching ACL in its filters.acls.values
          // @ts-ignore
          const hasMatchingAcl = item.filters.acls.values.some(value => acls.includes(value));

          // Ensure the item hasn't already been mapped
          const isNew = !seenIds.has(item.id);

          // Add item.id to seenIds if it meets criteria
          if (hasMatchingAcl && isNew) {
            seenIds.add(item.id);
            return true;
          }

          return false;
        })
        .map(item => ({
          id: item.id,
          name: item.name,
          values: item.filters.acls.values,
        }));

      }

  }

  async filterSubscription($event: any, subscription: string) {
    if(!this.isGlobal) {

      // if another subscription clicked on other than 'My Subscriptions' then disable 'My Subscriptions'
      // if((this.store.collection === Constants.ChatbotFiltersStores[0]) && (subscription !== 'my-subscriptions')) {
      if(subscription !== 'my-subscriptions') {
        let subscriptions = this.azureSearchService.get_subscription_filters_filters(this.store.collection);
        const mySubscriptions: any = subscriptions.find((item: any) => item.key === 'my-subscriptions');
        const subState: any = subscriptions.find((item: any) => item.key === subscription);

        if (mySubscriptions?.active && !subState?.active) {
          this.searchService.ss_set_subscription(this.store.collection, 'my-subscriptions', true);
        }
      }

      // if 'My Subscriptions' is clicked & enabled, then disable all other subscriptions
      // if((this.store.collection === Constants.ChatbotFiltersStores[0]) && (subscription === 'my-subscriptions')) {
      if(subscription === 'my-subscriptions') {
        let subscriptions = this.azureSearchService.get_subscription_filters_filters(this.store.collection);
        const activeKeys: string[] = this.getActiveKeys(this.azureSearchService.get_subscription_filters_filters(this.store.collection));
        activeKeys.forEach((subscription: string) => {
          this.searchService.ss_set_subscription(this.store.collection, subscription, true);
        });
      }

      this.searchService.ss_set_subscription(this.store.collection, subscription, true);
      this.searchService.search(this.store.collection).then((azssError: AzssError | null) => {

        // user selected subscriptions (for use by IA client)
        if(this.store.collection === Constants.ChatbotFiltersStores[0]) {
          const activeKeys: string[] = this.getActiveKeys(this.azureSearchService.get_subscription_filters_filters(this.store.collection));
          let payload: any[] = this.filterActiveItems(this.sharedService.getSubscriptionsUserACLs(), activeKeys);
          this.sharedService.updateChatbotSelectedFilters(payload);
        }

      }).catch((error: any) => {
      });
    }
  }

  getSubscriptionKey(index: number, subscription: any): string {
    return subscription.key;
  }

  subscriptionAllowed(subscriptionKey: string): boolean {
    let isAllowed: boolean = true;
    if(this.store.collection === Constants.ChatbotFiltersStores[0]) {
    } else {
      if(subscriptionKey === 'operator-profiles') {
        isAllowed = false;
      }
    }
    return isAllowed;
  }

  public testPermissions(filterString: string) {
    let isAllowed: boolean = true;
    if(this.store.collection === Constants.ChatbotFiltersStores[0]) {
      const regex: RegExp = /'([^']+)'/g;
      let match;
      const acls: string[] = [];
      while (match = regex.exec(filterString)) {
        acls.push(match[1]);
      }
      isAllowed = acls.some((acl: string) => this.userPermissions.includes(acl));
    }
    return isAllowed;
  }

  ngOnDestroy(): void {
    if(this.storeAzssSubscription !== Subscription.EMPTY) {
      this.storeAzssSubscription.unsubscribe();
    }
  }

}
