import {
  Component,
  AfterViewInit,
  ViewChild,
  ViewEncapsulation,
  OnInit,
  OnDestroy,
} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FormControl, ReactiveFormsModule} from '@angular/forms';

import {MatAutocompleteModule, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggle, MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDialog} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MAT_SELECT_CONFIG, MatSelectModule} from '@angular/material/select';

import {debounceTime, distinctUntilChanged, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {filter, mergeMap, take} from 'rxjs/operators';

import {Constants} from '../../constants/constants';

const reportsStores: string[] = Constants.ReportsStores;

import {AzureSearchService} from '../../services/azuresearch/azuresearch.service';
import {DashboardService} from '../../services/dashboard/dashboard.service';
import {DataService} from '../../services/data/data.service';
import {ReportsNavService} from '../../services/reportsnav/reportsnav.service';
import {SavedFiltersService} from '../../services/savedfilters/savedfilters.service';
import {SearchService} from '../../services/search/search.service';
import {SharedService} from '../../services/shared/shared.service';
import {UserService} from '../../services/user/user.service';

import {DownloadsViewComponent} from '../../views/downloads-view/downloads-view.component';
import {NewResearchComponent} from '../new-research/new-research.component';
import {ReportsHelpComponent} from '../../dialogs/reports-help/reports-help.component';
import {ReportsViewComponent} from '../../views/reports-view/reports-view.component';
import {SearchFiltersComponent} from '../../features/search-filters/search-filters.component';

import {ActiveFilter, createEmptyActiveFilter} from '../../interface/activefilter';
import {AzssError, AzssStore} from '../../interface/azss';
import {Favorite} from '../../interface/favorite';
import {Research} from '../../interface/research';
import {environment as ENV} from '../../../environments/environment';
import {MatTooltipModule} from '@angular/material/tooltip';
import {LogService} from '../../services/log/log.service';
import {ChatbotComponent} from '../../features/chatbot/chatbot.component';
import {ChatbotFiltersComponent} from '../../features/chatbot-filters/chatbot-filters.component';
import {MomentModule} from 'ngx-moment';
import {FileSizePipe} from '../../pipes/filesize/filesize.pipe';

@Component({
  selector: 'iv-reports-nav',
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatMenuModule,
    ReactiveFormsModule,
    ReportsHelpComponent,
    ReportsViewComponent,
    SearchFiltersComponent,
    DownloadsViewComponent,
    NewResearchComponent,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    ChatbotComponent,
    ChatbotFiltersComponent,
    NgOptimizedImage,
    RouterLink,
    MomentModule,
    FileSizePipe,
  ],
  templateUrl: './reports-nav.component.html',
  styleUrls: ['./reports-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {overlayPanelClass: 'navigation-tabs'},
    }
  ]
})
export class ReportsNavComponent implements OnInit, OnDestroy, AfterViewInit {

  searchCtrl = new FormControl();
  clearReportsSearchBoxSub: Subscription = Subscription.EMPTY;
  blockSearchCtrlInfinite: boolean = false;

  // main.service: controls whether reports facets are displayed
  showReportFacetsSub: Subscription = Subscription.EMPTY;
  showReportFacets: boolean = false;

  // controls whether chatbot facets are displayed
  showChatbotFacetsSub: Subscription = Subscription.EMPTY;
  showChatbotFacets: boolean = false;

  // search.service (instance): subscription and data matching the active store in reports-nav
  reportsNavAzssData: any;  // AzssStore;
  reportsNavAzssSubscription: Subscription;

  reportsFacetsPrettyTextSubscription: Subscription = Subscription.EMPTY;
  chatbotFacetsPrettyTextSubscription: Subscription = Subscription.EMPTY;
  facetsPrettyText: string = '';

  research: Research[] = [];
  researchItem: Research | undefined;
  researchSubscription: Subscription = Subscription.EMPTY;

  activeFilterSub: Subscription = Subscription.EMPTY;
  activeFilter: ActiveFilter = createEmptyActiveFilter();

  filtersLoadedSub: Subscription = Subscription.EMPTY;
  defaultFilterId: number = 0;

  loadingSavedFilterSub: Subscription = Subscription.EMPTY;
  loadingSavedFilter: boolean = false;

  loadingSavedUrlFilterSub: Subscription = Subscription.EMPTY;
  loadingSavedUrlFilter: string = '';

  loadUrlFilterSub: Subscription = Subscription.EMPTY;

  loadingFilterAsNewSub: Subscription = Subscription.EMPTY;
  loadingFilterAsNew: any = null;

  // controls whether Reports pane is displaying search results or other pages
  showReportsSearchResults: boolean = true;

  // access the counts for new articles per tab
  tabCountsSub: Subscription = Subscription.EMPTY;
  tabCounts: any = {};

  activeStoreKey: string = '';

  blockInfinite: boolean = false;

  orderBy: string = 'dateDesc';

  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;

  @ViewChild('reportsToggle') reportsToggle?: MatButtonToggle;

  favoritesSubscription: Subscription = Subscription.EMPTY;
  favoritesDb: Favorite[] = [];

  noResultsMsg: string = '';

  genaiChatBotPermission = false;

  isProduction: boolean = false;

  suggestSubscription: Subscription = Subscription.EMPTY;

  lastSearchTerm: string = '';

  selectedIcon: string = 'relevance';

  constructor(private route: ActivatedRoute,
              public dialog: MatDialog,
              private azureSearchService: AzureSearchService,
              private dashboardService: DashboardService,
              private dataService: DataService,
              private reportsNavService: ReportsNavService,
              private router: Router,
              private savedFiltersService: SavedFiltersService,
              public searchService: SearchService,
              public sharedService: SharedService,
              public userService: UserService,
              private logService: LogService,
  ) {

    // when search.service store/data is changed, get the new data for search results
    this.reportsNavAzssSubscription = this.searchService.reportsAzss$.subscribe((data: any) => {
      this.reportsNavAzssData = data;

      // also update an communication service so search-filters on the reports-nav pane gets updated
      this.reportsNavService.setReportsNavAzssData(data);
    });

    this.loadingSavedUrlFilterSub = this.sharedService.reportsSavedUrlFilter$.subscribe((data: any) => {
      this.loadingSavedUrlFilter = data;
    });

    this.loadUrlFilterSub = this.sharedService.reportsLoadUrl$.subscribe((urlkey: string) => {
      if ((typeof (urlkey) === 'string') && urlkey.length) {
        this.loadFilterUrl(urlkey);
      }
    });

    this.isProduction = ENV.production !== undefined ? ENV.production : false;
  }

  ngOnInit(): void {
    const user = this.userService.getUserPermissions();
    this.genaiChatBotPermission = user.permissions.includes('GENAI_CHATBOT');

    // start the reports pane on a specific tab
    if (typeof (this.route.snapshot.queryParams[Constants.reportsPaneTab]) !== 'undefined') {
      this.setCollection(this.route.snapshot.queryParams[Constants.reportsPaneTab]);
    }

    // start the reports pane on Instant Analyst tab and prepopulate with a query
    if (this.genaiChatBotPermission) {
      if (this.router.routerState.snapshot.url.split('/').at(-2) === Constants.chatbotQuery && this.route.snapshot.paramMap.get(Constants.chatbotQuery)) {
        const query = this.route.snapshot.paramMap.get(Constants.chatbotQuery) || "";
        this.sharedService.updateChatbotLoadQuery(query);
        this.setCollection(Constants.ChatbotFiltersStores[0]);
      }
    }

    this.clearReportsSearchBoxSub = this.dashboardService.clearReportsSearchBox$.subscribe(value => {
      if (this.searchCtrl && value) {
        this.searchCtrl.setValue('');
      }
    });

    this.showReportFacetsSub = this.dashboardService.showReportFacets.subscribe(value => {
      this.showReportFacets = value;
    });

    this.showChatbotFacetsSub = this.dashboardService.showChatbotFacets.subscribe(value => {
      this.showChatbotFacets = value;
    });

    this.tabCountsSub = this.searchService.newCounts.subscribe(value => {
      this.tabCounts = value;
    });

    this.loadingSavedFilterSub = this.sharedService.reportsLoadingSavedFilter$.subscribe(value => {
      this.loadingSavedFilter = value;
    });

    this.loadingFilterAsNewSub = this.sharedService.reportsLoadFilterAsNew$.subscribe(filter => {
      if(filter) {
        this.loadingFilterAsNew = filter;
      }
    });

    this.favoritesSubscription = this.userService.userFavorites$.subscribe((favorites: Favorite[]) => {
      this.favoritesDb = favorites;

      let azssError: any;
      let searchParameters: Object = {};
      let searchParametersNew: any = Object.assign({}, searchParameters);
      let idCount: number = 0;
      searchParametersNew.additionalFilters = `(id eq '01802467436734763482963946783428068374026783456')`;

      // need to ensure we are not requesting a page beyond what is available... for instance if you have 20 per page
      // and you are on page 3 and delete the 41st bookmark then future queries will fail as it is still trying
      // to request results for page 3
      let searchParametersOld = this.azureSearchService.get_search_parameters('Bookmarks');
      if ((this.favoritesDb.length > 0) && (searchParametersOld.skip >= this.favoritesDb.length)) {
        searchParametersNew.skip = searchParametersOld.skip - searchParametersOld.top;
      }
      if (this.favoritesDb.length) {
        searchParametersNew.additionalFilters = `(`;
        this.favoritesDb.forEach(function (favorite: Favorite) {
          searchParametersNew.additionalFilters += `${idCount > 0 ? ' or ' : ''}(id eq '${favorite.id}')`;
          idCount += 1;
        });
        searchParametersNew.additionalFilters += `)`;
      }

      if ((azssError = this.azureSearchService.update_search_parameters('Bookmarks', searchParametersNew))) {
      }

      if (this.activeStoreKey === 'Bookmarks') {
        this.azureSearchService.search('Bookmarks');
      }

    });

    this.reportsFacetsPrettyTextSubscription = this.sharedService.reportsFacetsPrettyText$.subscribe((text: string) => {
      if (this.activeStoreKey !== Constants.ChatbotFiltersStores[0]) {
        this.facetsPrettyText = text;
      }
    });

    this.chatbotFacetsPrettyTextSubscription = this.sharedService.chatbotFacetsPrettyText$.subscribe((text: string) => {
      if (this.activeStoreKey === Constants.ChatbotFiltersStores[0]) {
        this.facetsPrettyText = text;
      }
    });

    let defaultFilterIdSet = false; // Flag to track if defaultFilterId is set
    this.filtersLoadedSub = this.savedFiltersService.filtersLoaded$.pipe(
      filter(filtersLoaded => filtersLoaded), // Wait until filtersLoaded is true
      switchMap(() => this.processLoadedFiltersAndSelectDefault().pipe(
        tap((defaultFilterId: number) => {
          if ((this.loadingSavedUrlFilter === '') && (!this.loadingFilterAsNew)) {
            this.defaultFilterId = defaultFilterId;
            defaultFilterIdSet = true; // Set the flag when defaultFilterId is set
          }
        })
      )),
      mergeMap(() => this.sharedService.reportsTab$) // reportsTabSub subscription occurs after this point
    ).subscribe(value => {

      this.activeStoreKey = value;

      if (this.activeStoreKey === '') {
        if (typeof (this.route.snapshot.queryParams[Constants.reportsPaneQuery]) !== 'undefined') {
          this.sharedService.updateReportsBlockNextSuggest(true);
          this.searchService.setQuery(this.activeStoreKey, this.route.snapshot.queryParams[Constants.reportsPaneQuery]);
          this.searchService.search(this.activeStoreKey).then((azssError: AzssError | null) => {
            this.noResultsMsg = '';
          }).catch((error: any) => {
            this.noResultsMsg = error.message;
          });
        }
      }

      if (!this.blockInfinite) {

        if(this.loadingFilterAsNew) {
          let filter: any = this.sharedService.getReportsLoadFilterAsNew();
          if(filter) {
            this.loadFilterAsNew(filter);
          }
        }

        if (this.loadingSavedUrlFilter !== '') {
          const urlkey = this.route.snapshot.queryParams[Constants.reportsSavedFiltersUrl];

          this.loadingSavedUrlFilter = '';

          if ((typeof (urlkey) === 'string') && urlkey.length) {
            this.loadFilterUrl(urlkey);
          }

        } else {

          if (defaultFilterIdSet) {
            if (this.defaultFilterId > 0) {
              this.sharedService.updateReportsLoadingSavedFilter(true);
            }
            this.setCollection(value);
            if (this.defaultFilterId > 0) {
              this.sharedService.updateReportsLoadingSavedFilter(false);
            }

            this.activeStoreKey = value;
          }

        }

      }

    });

    this.activeFilterSub = this.sharedService.reportsActiveFilter$.subscribe(af => {
      this.activeFilter = af;
      if((this.activeFilter.id === 0) && (this.activeFilter.collection === '')) {
        this.activeFilter.collection = 'Reports';
      }
      if (!this.blockSearchCtrlInfinite) {
        if ((this.activeStoreKey === this.activeFilter.collection) && this.activeStoreKey.length && this.activeFilter.hasOwnProperty('q') && (this.activeFilter.q !== undefined)) {
          this.blockSearchCtrlInfinite = true;
          setTimeout(() => {
            this.sharedService.updateReportsBlockNextSuggest(true);
            this.searchCtrl.setValue(this.activeFilter.q);
            this.blockSearchCtrlInfinite = false;
          });
        }
      }
    });

    this.selectedIcon = this.sharedService.getCookie(Constants.sortReportPaneCookie) || 'relevance';

  }

  loadFilterUrl(urlkey: string): void {

    this.dataService.loadFilterUrl(urlkey).then(result => {

      let filter = result.result.filter.filter;
      let q = filter.q;

      // set the facets to the loaded filter
      this.blockInfinite = true;
      this.sharedService.updateReportsTab(filter.collection);
      this.azureSearchService.clear_all_facets(filter.collection, false);
      this.azureSearchService.set_facets_diff(filter.collection, filter.facetsdiff);
      this.searchService.ss_set_subscription(filter.collection, '', false);
      this.azureSearchService.facetsDiff_to_facets(filter.collection, true);
      this.searchService.setQuery(filter.collection, q);
      this.searchService.ss_set_collection(filter.collection, true);
      this.activeStoreKey = filter.collection;

      this.blockInfinite = false;

    });

  }

  loadFilterAsNew(filter: any): void {
    let q = filter.q;

    // set the facets to the loaded filter
    this.blockInfinite = true;

    this.sharedService.updateReportsTab(filter.collection);
    this.azureSearchService.clear_all_facets(filter.collection, false);
    this.azureSearchService.set_facets_diff(filter.collection, filter.facetsDiff);
    this.searchService.ss_set_subscription(filter.collection, '', false);
    this.azureSearchService.facetsDiff_to_facets(filter.collection, true);
    this.searchService.setQuery(filter.collection, q);
    this.searchService.ss_set_collection(filter.collection, true);
    this.activeStoreKey = filter.collection;

    this.blockInfinite = false;
  }

  ngAfterViewInit(): void {

    this.researchSubscription = this.searchService.research$.subscribe((research) => {
      // console.log(JSON.parse(JSON.stringify(research,null,2)));
      // console.log(`reports-nav::this.searchService.research$.subscribe() this.activeStoreKey ${this.activeStoreKey}`)
      this.research = research;
      const researchRec: Research | undefined = this.research.find(rec => rec.activeStoreKey === this.activeStoreKey);
      if (researchRec !== undefined) {
        this.researchItem = JSON.parse(JSON.stringify(researchRec));
      }
      if (this.activeStoreKey === Constants.ChatbotFiltersStores[0]) {
        const [research] = this.research.filter(({activeStoreKey}) => activeStoreKey === 'Reports');
        if (research) {
          this.researchItem = JSON.parse(JSON.stringify(research));
        }
      }
    });

    this.suggestSubscription = this.searchCtrl.valueChanges.pipe(
      debounceTime(Constants.suggestDebounceMs),
      distinctUntilChanged(),
      tap(val => {
        if (typeof (val) === 'string') {
          if (val.length) {
            if (this.autocomplete) {
              if (this.sharedService.getReportsBlockNextSuggest() === false) {
                this.autocomplete.openPanel();
              }
            }
          } else {
            if (this.autocomplete) {
              this.autocomplete.closePanel();
            }
          }
        }
      }),
      switchMap(val => {
        if (typeof (val) === 'string') {
          this.searchService.setQuery(this.activeStoreKey, val);
          if (typeof (val) === 'string' && Constants.suggestResults) {
            // Perform the side-effect, then return an empty observable.
            if (this.sharedService.getReportsBlockNextSuggest() === false) {
              this.searchService.suggest(this.activeStoreKey, val);
            } else {
              this.sharedService.updateReportsBlockNextSuggest(false);
            }
            return of([]); // Return a no-op observable to satisfy the type requirement
          }
        }
        // Ensure an Observable is returned from all paths
        return of([]);
      })
    ).subscribe(results => {
      // Handle the observable update from the other service here if needed
    });


  }

  activateToggle(toggle: MatButtonToggle) {
    if (toggle) {
      toggle.checked = true; // This will make the button toggle active
    }
  }

  processLoadedFiltersAndSelectDefault(): Observable<number> {
    return this.sharedService.reportsDefaultFilterId$;
  }

  public setCollection(collection: string, userSelected: boolean = false) {

    if ((reportsStores.indexOf(collection) >= 0) || (collection === Constants.ChatbotFiltersStores[0])) {

      // if an active filter, load it
      let activeFilterId: number = this.savedFiltersService.getActiveFilterId(collection);

      // otherwise if a default filter, load it (but only the first time!)
      if (activeFilterId === 0) {
        if (!this.savedFiltersService.getDefaultFilterLoaded(collection)) {
          this.savedFiltersService.setDefaultFilterLoaded(collection);
          activeFilterId = this.savedFiltersService.getDefaultFilterId(collection);
        }
      }

      if (activeFilterId > 0) {

        if (!this.loadingSavedFilter) {
          this.savedFiltersService.loadFilter(collection, activeFilterId);
        }

      } else {

        this.blockSearchCtrlInfinite = true;
        if(userSelected) {
          this.sharedService.clearActiveFilter(collection);
        }
        const researchRec: Research | undefined = this.research.find(rec => rec.activeStoreKey === collection);
        if (researchRec !== undefined) {
          this.sharedService.updateActiveFilterParameter(collection, 'q', researchRec.q);  // FIXME: PREVENT SAVING PARTIAL QUERIES?  parameters.input
          this.sharedService.updateActiveFilterParameter(collection, 'collection', collection);
        }
        this.blockSearchCtrlInfinite = false;

        if (collection === 'ReportsNew') {
          this.dashboardService.updateShowReportFacets(false);
        }

        this.showReportsSearchResults = true;

        this.activeStoreKey = collection;

        if (!this.blockInfinite) {
          this.blockInfinite = true;
          this.sharedService.updateReportsTab(collection);
          this.blockInfinite = false;
        }

        if (!this.loadingSavedFilter) {

          if(collection === Constants.ChatbotFiltersStores[0]) {

            this.searchService.ss_set_collection(collection);
            this.azureSearchService.set_all_facets_group_combine_using_and(collection, false);
            this.searchService.chatbotAzssSubject.next(this.azureSearchService.store[Constants.ChatbotFiltersStores[0]]);

          } else {
            this.searchService.ss_set_collection(collection);

          }

        }

      }

    }

    // ONLY log this event if the user clicked a tab to change it (was triggering every dashboard redraw for every pane)
    if (userSelected) {
      this.logService.track('collection_change_selected', false, {
        vault_collection: collection
      });

    }

  }

  public setOrderBy(orderBy: string) {
    this.orderBy = orderBy;
    this.searchService.ss_set_order_by(this.activeStoreKey, orderBy);
    this.userService.setPreferences({search: {orderby: orderBy}});
    this.searchService.search(this.activeStoreKey).then((azssError: AzssError | null) => {
      this.noResultsMsg = '';
    }).catch((error: any) => {
      this.noResultsMsg = error.message;
    });
  }

  public showFilters($event: any) {
    if (this.showReportsSearchResults) {
      if (this.activeStoreKey !== Constants.ChatbotFiltersStores[0]) {
        this.dashboardService.updateShowReportFacets(!this.showReportFacets);
      } else {
        this.dashboardService.updateShowChatbotFacets(!this.showChatbotFacets);
      }
    }
  }

  public showFilterHelp($event: any) {
    const dialogRef = this.dialog.open(ReportsHelpComponent, {
      panelClass: 'iv-reports-help-dialog',
      disableClose: false,
      autoFocus: true,
      width: '300px',
      height: 'auto',
      data: ''
    });
  }

  public clearSearchText(event: any) {
    if (this.autocomplete) {
      this.autocomplete.closePanel();
    }
    this.searchService.setQuery(this.activeStoreKey, '');
    this.sharedService.updateActiveFilterParameter(this.activeStoreKey, 'q', '');
    setTimeout(() => {
      this.searchCtrl.setValue('');  // NEW
    });
    this.searchService.navigateToPage(this.activeStoreKey, '', 1);
    this.searchService.checkActiveFilterChanged(this.activeStoreKey, '');
  }

  public htmlToPlaintext(text: string): string {
    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
  }

  public navigateToSuggested(suggestion: any) {
    if (this.activeStoreKey !== 'Downloads') {
      if (this.autocomplete) {
        this.autocomplete.closePanel();
      }
      this.dashboardService.clearReportsSearchBox();
      this.router.navigate([`/research/${suggestion.id}`]);
    } else {
      this.dashboardService.downloadAttachment(suggestion.parentId, suggestion.filename);
    }
    this.searchCtrl.setValue(this.lastSearchTerm, {emitEvent: false});
  }

  getSuggestionId(index: number, suggestion: any): string {
    return suggestion.id;
  }

  performSearch(event: KeyboardEvent | MouseEvent, q: string) {
    event.preventDefault(); // Prevent default form submission behavior

    if ((event instanceof KeyboardEvent && (event.key === 'Enter' || event.key === 'Return')) || (event instanceof PointerEvent)) {
      if (q.endsWith('\n') || q.endsWith('\r')) {
        q = q.trim(); // Strip trailing Enter characters
      }

      if (this.autocomplete) {
        this.autocomplete.closePanel();
      }
      // this.sharedService.updateReportsBlockNextSuggest(true);      // bug?
      this.searchService.navigateToPage(this.activeStoreKey, q, 1);
      this.sharedService.updateActiveFilterParameter(this.activeStoreKey, 'q', q);
      this.searchService.checkActiveFilterChanged(this.activeStoreKey, '');
    }

  }

  closeAutocomplete(): void {
    if (this.autocomplete) {
      this.autocomplete.closePanel();
    }
  }

  public captureSearchTerm(event: Event) {
    const inputElement: HTMLInputElement = event.target as HTMLInputElement;
    this.lastSearchTerm = inputElement.value;
  }

  selectIcon(icon: string) {
    this.selectedIcon = icon;
    switch (icon) {
      case 'relevance':
        this.azureSearchService.update_search_parameters(this.activeStoreKey, {'scoringProfile': ENV.SEARCH.scoringProfile});
        this.searchService.ss_set_order_by(this.activeStoreKey, '');
        break;
      case 'calendar_month':
        this.azureSearchService.update_search_parameters(this.activeStoreKey, {'scoringProfile': ENV.SEARCH.scoringProfile});
        this.searchService.ss_set_order_by(this.activeStoreKey, 'publishedDate desc');
        break;
      case 'event_available':
        this.azureSearchService.update_search_parameters(this.activeStoreKey, {'scoringProfile': 'Date-order'});
        this.searchService.ss_set_order_by(this.activeStoreKey, '');
        break;
    }
    this.sharedService.setCookie(Constants.sortReportPaneCookie, icon, Constants.sortOrderExpiryDays);
    this.searchService.ss_set_collection(this.activeStoreKey);
    // this.azureSearchService.dump_stores(this.activeStoreKey);
  }

  ngOnDestroy() {
    this.clearReportsSearchBoxSub.unsubscribe();
    this.reportsNavAzssSubscription.unsubscribe();
    if (this.researchSubscription !== Subscription.EMPTY) {
      this.researchSubscription.unsubscribe();
    }
    this.showReportFacetsSub.unsubscribe();
    this.showChatbotFacetsSub.unsubscribe();
    this.tabCountsSub.unsubscribe();
    this.activeFilterSub.unsubscribe();
    this.filtersLoadedSub.unsubscribe();
    if (this.loadUrlFilterSub !== Subscription.EMPTY) {
      this.loadUrlFilterSub.unsubscribe();
    }
    this.loadingSavedFilterSub.unsubscribe();
    if (this.loadingFilterAsNewSub !== Subscription.EMPTY) {
      this.loadingFilterAsNewSub.unsubscribe();
    }
    this.favoritesSubscription.unsubscribe();
    if (this.suggestSubscription !== Subscription.EMPTY) {
      this.suggestSubscription.unsubscribe();
    }
    this.reportsFacetsPrettyTextSubscription.unsubscribe();
    this.chatbotFacetsPrettyTextSubscription.unsubscribe();
  }

}
