<div class="dialog-title">
  <span>Email Alerts<span [ngClass]="{'superscript-badge': true}" [ngStyle]="{'background-color': 'red', 'color': 'white'}">Beta</span></span>
  <button (click)="closeDialog()">
    <mat-icon>cancel</mat-icon>
  </button>
</div>
<div class="container">
  <section>
    <p>Receive email alerts for the filter: <strong>{{filter.title}}</strong> on the following schedule:</p>
    <form [formGroup]="filterSubscribeForm" (ngSubmit)="onSubmit()">
      <mat-radio-group formControlName="selectedOption">
        <mat-radio-button *ngFor="let option of radioOptions" [value]="option.value">
          {{ option.label }}
        </mat-radio-button>
      </mat-radio-group>
      <div *ngIf="keywordMessage.length" class="message">
        {{keywordMessage}}
      </div>
      <div class="actions">
        <button mat-button type="submit">Confirm</button>
        <button mat-button type="button" class="cancel-button" (click)="closeDialog()">Cancel</button>
      </div>
    </form>
  </section>
</div>
