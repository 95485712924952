import {AzssStore} from "./azss";

export interface Research {
  activeStoreKey:           string;         // active store
  activeFilterId:           number;         // active filter id for the current document store, else 0
  page:                     number;         // active page number
  q:                        string;         // search text from search box ui
  chipContents?:            string;         // the displayed chip contents
  defaultFilterId:          number;         // the default filter id #
  defaultFilterLoaded:      boolean;        // has the default filter been loaded (load only once)
  // transactionId?:           string;         // unique transaction id (for change detection)
}

export interface ResearchResults {
    results:                Research[];
}

export function createEmptyResearch(storeKey: string): Research {
  return {
    activeStoreKey: storeKey,
    activeFilterId: 0,
    page: 1,
    q: '',
    chipContents: '',
    defaultFilterId: 0,
    defaultFilterLoaded: false,
  };
}
