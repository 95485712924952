import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, FormControl} from "@angular/forms";
import {LogService} from "../../../services/log/log.service";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";

@Component({
  selector: 'iv-rephrase-question',
  standalone: true,
  imports: [CommonModule, FormsModule, MatSlideToggleModule, MatButtonModule, MatTooltipModule],
  templateUrl: './rephrase-question.component.html',
  styleUrls: ['./rephrase-question.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RephraseQuestionComponent {
  @Input() rephraseQuestion: boolean = true; // = new FormControl<boolean>(true);
  @Input() allowedFeature = false;
  @Output() rephraseQuestionChange = new EventEmitter<boolean>();

  constructor(private logService: LogService) {
  }

  getTooltipText(): string {
    return this.rephraseQuestion ? 'Allowing Instant Analyst to rephrase your questions.' : 'Disallowing Instant Analyst to rephrase your questions.';
  }

  changeRephrase(event: any) {
    this.rephraseQuestionChange.emit(event.checked);
  }

}
