import {Inject, Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment as ENV} from '../../../environments/environment';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {AuthService} from '@auth0/auth0-angular';

@Injectable()
export class CgwInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService, private router: Router) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('/cgw/')) {
      this.auth.isAuthenticated$.pipe(take(1)).subscribe(value => {
        if (!value) {
          this.router.navigate(['']);
        }
      });
      request = request.clone({
          headers: request.headers.append('intelligence-api-context', ENV.review ? 'Review' : 'Desktop')
        }
      );
    }
    return next.handle(request);
  }
}
