import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators
} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MAT_SELECT_CONFIG, MatSelectModule} from '@angular/material/select';
import {HttpClient} from "@angular/common/http";
import {DataService} from "../../../services/data/data.service";
import {LogService} from "../../../services/log/log.service";
import {UserService} from "../../../services/user/user.service";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";

@Component({
  selector: 'iv-feedback',
  standalone: true,
  imports: [CommonModule, MatSelectModule, MatDialogModule, MatButtonModule, ReactiveFormsModule, MatInputModule, MatIconModule],
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FeedbackComponent implements OnInit {
  feedBackForm = new FormGroup({
    subject: new FormControl(null, [Validators.required]),
    type: new FormControl(null, [Validators.required]),
    priority: new FormControl(null, [Validators.required]),
    description: new FormControl(null, [Validators.required]),
    steps: new FormControl(null),
    useCase: new FormControl(null),
    attachments: new FormControl(null, {validators: [this.fileSizeValidator.bind(this)]} )
  });

  error: boolean = false;
  success: boolean = false;
  files: any = [];
  fileSize: number = 0;

  constructor(public dialogRef: MatDialogRef<FeedbackComponent>,
              private http: HttpClient,
              private dataService: DataService,
              private cdr: ChangeDetectorRef,
              private userService: UserService,
              private logService: LogService
  ) {
  }

  ngOnInit() {
    let formType = this.feedBackForm.get('type');
    if(formType) {
      formType.valueChanges.subscribe(val => {
        switch (true) {
          case (val === 'Enhancement'):
          case (val === 'Bug'):
            this.feedBackForm.controls['priority'].setValidators([Validators.required]);
            this.feedBackForm.controls['priority'].updateValueAndValidity();
            break;
          case (val === 'General Support'):
            this.feedBackForm.controls['priority'].clearValidators();
            this.feedBackForm.controls['priority'].updateValueAndValidity();
            break;
        }
      });
    }
  }

  onFileChange(event: any) {
    // Reset the file array so we don't get duplicate files or remembered files.
    // We do this before checking for valid new files as the event could be a delete (of existing files)
    this.files = [];
    if (event.target.files && event.target.files[0]) {
      for (let file of event.target.files) {
        let reader = new FileReader();
        reader.onload = () => {
          this.fileSize = this.fileSize + file.size;
          this.feedBackForm.controls['attachments'].updateValueAndValidity();
          if(reader.result !== null) {
            const dataURL: string | ArrayBuffer = reader.result;
            const base64 = (dataURL as string).slice((dataURL as string).indexOf(',') + 1);
            this.files.push({
              file: {
                'lastModified': file.lastModified,
                'lastModifiedDate': file.lastModifiedDate,
                'name': file.name,
                'size': file.size,
                'mimetype': file.type
              },
              blob: base64  //Base64 string for preview image
            });
          }
        };
        reader.readAsDataURL(file);
      }
    }
  }

  isFieldValid(field: string) {
    let isValid: boolean = false;
    let formField = this.feedBackForm.get(field);
    if(formField) {
      isValid = !formField.valid && formField.touched;
    }
    return isValid;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onSubmit() {
    let fbFormData = this.toFormData(this.feedBackForm.value);

    if (this.feedBackForm.valid) {

      fbFormData.append('files', JSON.stringify(this.files));
      fbFormData.append('profile', JSON.stringify(this.userService.getUserProfile()));

      this.dataService.submitFeedback(fbFormData).subscribe(
        data => {

          this.logService.track("feedback_button_selected", false,{});

          this.error = false;
          this.success = true;
        },
        err => {
          this.success = false;
          this.error = true;
        }
      );
    } else {
      this.validateAllFormFields(this.feedBackForm);
    }
  }

  toFormData<T extends Record<string, any>>(formValue: T): FormData {
    let formData = new FormData();

    for (const [key, value] of Object.entries(formValue)) {
      if (value instanceof File) {
        formData.append(key, value, value.name);
      } else if (value instanceof Blob) {
        // Handle Blob types (e.g., omit the 'name' property)
        formData.append(key, value);
      } else {
        formData.append(key, String(value));
      }
    }

    formData.append('UserAgent', this.getMeta()); // append metadata
    return formData;
  }

  getMeta() {
    const page = window.location.pathname;
    const userAgent = window.navigator.userAgent;
    const screen = window.screen.width + 'x' + window.screen.height;
    const viewport = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) + 'x' + (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
    return `Location:${page}\nBrowser: ${userAgent}\nScreen: ${screen}\nViewport:${viewport}`
  }

  get subject() {
    return this.feedBackForm.get('subject');
  }

  get type() {
    return this.feedBackForm.get('type');
  }

  get priority() {
    return this.feedBackForm.get('priority');
  }

  get description() {
    return this.feedBackForm.get('description');
  }

  closeFeedback() {
    this.dialogRef.close();
  }

  fileSizeValidator(control: AbstractControl): ValidationErrors | null {
    if ((this.fileSize / 1048576) > 20) {
      return {validUrl: true};
    }
    return null;
  }

}
