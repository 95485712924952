import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

import { Research } from "../../interface/research";

export interface ResearchStoreState {
  research: Research[];
}

const defaultState: ResearchStoreState = {
  research: [],
};

@Injectable()
export class ResearchStore extends ComponentStore<ResearchStoreState> {
  constructor() {
    super(defaultState);
  }


  // --------------------------------------------------------------------------
  //  updaters
  // --------------------------------------------------------------------------

  // replace Research[] with new array of Research objects
  readonly loadResearch = this.updater((state, research: Research[] | null) => ({
    ...state,
    research: research || [],
  }));

  // add a Research object to Research[]
  readonly addResearch = this.updater( (state, research: Research) => ({
    research: [...state.research, research],
  }));

  // update a single Research object in the Research[] by activeStoreKey
  readonly updateResearchByActiveStoreKey = this.updater((state, payload: { activeStoreKey: string, research: Research }) => ({
    ...state,
    research: state.research.map(research => research.activeStoreKey === payload.activeStoreKey ? payload.research : research)
  }));


  // --------------------------------------------------------------------------
  //  selectors
  // --------------------------------------------------------------------------

  // return Research[] array as observable
  selectResearch(): Observable<Research[]> {
    return this.select(state => state.research);
  }

  // return a single Research object in the array by activeStoreKey
  readonly selectResearchByActiveStoreKey = (activeStoreKey: string): Observable<Research | undefined> =>
    this.select(state => state.research.find(research => research.activeStoreKey === activeStoreKey))

}
