<article>
  <h1>404!</h1>
  <p>Failed to find the filter corresponding to alert id: {{alertId}}. Either the alert has been deleted or you do not have access to the filter.</p>
  <br/>
  <ng-container *ngIf="profile">
    <p>The URL: <a href="{{attemptedUrl}}">{{ attemptedUrl }}</a> you are attempting to access can not be found.</p>
    <p *ngIf="profile.email">Email: {{profile.email}}</p>
    <p>Date/time: {{dateTimeNow}} (GMT)</p>
    <br/>
  </ng-container>
  <p><a href="/dashboard">&#8592; Go back to the main dashboard.</a></p>
</article>
