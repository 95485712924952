<section [ngSwitch]="errorCode">
  <article *ngSwitchCase="'authentication'">
    <h1>We've encountered an authentication error.</h1>
    <p>Please try again in a few minutes. If you continue to experience this error, please reach out to support at: <a href="mailto:EnergyAnalyticsSupport@enverus.com?subject=Authentication Error">EnergyAnalyticsSupport@enverus.com</a></p>
    <h2>Error: Authentication / Auth0 | {{currentDate}}</h2>
  </article>
  <article *ngSwitchCase="'authorization'">
    <h1>We've encountered an authorization error.</h1>
    <p>Please try again in a few minutes. If you continue to experience this error, please reach out to support at: <a href="mailto:EnergyAnalyticsSupport@enverus.com?subject=Authentication Error">EnergyAnalyticsSupport@enverus.com</a></p>
    <h2>Error: Authorization / Enverus  | {{currentDate}}</h2>
  </article>
  <article *ngSwitchDefault>
    <h1>We've encountered an error.</h1>
    <p>Please try again in a few minutes. If you continue to experience this error, please reach out to support at: <a href="mailto:EnergyAnalyticsSupport@enverus.com?subject=Authentication Error">EnergyAnalyticsSupport@enverus.com</a></p>
    <p><a href="/dashboard">&#8592; Go back to the main dashboard.</a></p>
    <h2>Error: Unknown / Vault  | {{currentDate}}</h2>
    <ng-container *ngIf="attemptedUrl.length">
      <p>URL: <a href="{{attemptedUrl}}">{{ attemptedUrl }}</a></p>
    </ng-container>
  </article>
  <ng-container *ngIf="user$ | async as user">
    <div class="profile-grid">
      <div class="profile__header">
        <div class="profile__headline">
          <h2 class="profile__title">{{ user.name }}</h2>
          <span class="profile__description">{{ user.email }}</span>
        </div>
      </div>
      <ng-container *ngIf="code$ | async as code">
        <div class="profile__details">
          {{code}}
        </div>
      </ng-container>
    </div>
  </ng-container>
</section>

