<section>
  <button (click)="selectDateRange('today')">TODAY</button>
  <button (click)="selectDateRange('7')">7d</button>
  <button (click)="selectDateRange('30')">30d</button>
  <button (click)="selectDateRange('90')">90d</button>
  <button (click)="selectDateRange('all')">ALL</button>
</section>
<label class="date-label">FROM DATE:</label>
<mat-form-field id="start-form">
  <input matInput [matDatepicker]="startPicker" [matDatepickerFilter]="dateFilter" [min]="minDate" [(ngModel)]="startDate[storeKey]" [disabled]="isGlobal === true" (dateChange)="searchService.ss_search_from_facet(storeKey, {type:'RangeFacet', category:'publishedDate'}, {startDate: startDate[storeKey], endDate: endDate[storeKey]})" placeholder="Choose a date">
  <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
  <mat-datepicker [disabled]="isGlobal === true" #startPicker></mat-datepicker>
</mat-form-field>
<br><label class="date-label">TO DATE: </label>
<mat-form-field id="end-form">
  <input matInput [matDatepicker]="endPicker" [matDatepickerFilter]="dateFilter" [(ngModel)]="endDate[storeKey]" [disabled]="isGlobal === true" (dateChange)="searchService.ss_search_from_facet(storeKey,{type:'RangeFacet', category:'publishedDate'}, {startDate: startDate[storeKey], endDate: endDate[storeKey]})" placeholder="Choose a date">
  <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
  <mat-datepicker [disabled]="isGlobal === true" #endPicker></mat-datepicker>
</mat-form-field>
