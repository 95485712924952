import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from "../../services/user/user.service";
import {NgIf} from "@angular/common";

@Component({
  standalone: true,
  selector: 'iv-four-o-four-page',
  templateUrl: './four-o-four-page.component.html',
  styleUrls: ['./four-o-four-page.component.scss'],
  imports: [
    NgIf
  ],
  encapsulation: ViewEncapsulation.None
})

export class FourOFourPageComponent implements OnInit {
  public attemptedUrl: string = '';
  profile: any;
  public dateTimeNow: string = new Date().toISOString();

  constructor(private router: Router, private route: ActivatedRoute, private userService: UserService) {
    this.profile = this.userService.getUserProfile();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.attemptedUrl = params['attemptedUrl'] || this.router.url;
    });
  }

}
