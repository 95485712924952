import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EmailPreferencesComponent} from '../../features/user-preferences/email-preferences/email-preferences.component';

@Component({
  selector: 'iv-preferences',
  standalone: true,
  imports: [CommonModule, EmailPreferencesComponent],
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PreferencesComponent {

}
