<div class="dialog-title">
  <span>Send Feedback</span>
  <button (click)="closeFeedback()">
    <mat-icon>cancel</mat-icon>
  </button>
</div>
<div mat-dialog-content class="content">
  <form [formGroup]="feedBackForm" (ngSubmit)="onSubmit()" *ngIf="!success">
    <section [ngClass]="displayFieldCss('subject')">
      <label for="subject"><span>*</span> Subject:</label>
      <input id="subject" matInput type="text" formControlName="subject" placeholder="Quick summary of issue">
      <p>Quick Summary is required.</p>
    </section>

    <section [ngClass]="displayFieldCss('type')">
      <label for="type"><span>*</span> Feedback Type:</label>
      <mat-select id="type" formControlName="type" placeholder="Please Select">
        <mat-option value="Enhancement">Enhancement</mat-option>
        <mat-option value="Bug">Bug</mat-option>
        <mat-option value="General Support">General Support</mat-option>
      </mat-select>
      <p>Feedback type is required.</p>
    </section>

    <section [ngClass]="displayFieldCss('priority')" *ngIf="feedBackForm.value.type !== '' && feedBackForm.value.type !== 'General Support'">
      <label for="priority"><span>*</span> Severity:</label>
      <mat-select id="priority" formControlName="priority" placeholder="Please Select">
        <mat-option value="Low">Low</mat-option>
        <mat-option value="Medium">Medium</mat-option>
        <mat-option value="High">High</mat-option>
        <mat-option value="Critical">Critical</mat-option>
      </mat-select>
      <p>Severity is required.</p>
    </section>

    <section [ngClass]="displayFieldCss('description')">
      <label for="description"><span>*</span> Feedback:</label>
      <textarea id="description" matInput formControlName="description" rows="4" placeholder="Detailed description of issue"></textarea>
      <p>Feedback is required.</p>
    </section>

    <section [ngClass]="displayFieldCss('steps')" *ngIf="feedBackForm.value.type === 'Bug'">
      <label for="steps">Steps to reproduce</label>
      <textarea id="steps" matInput formControlName="steps" rows="4" placeholder="Action(s) required to reproduce the bug"></textarea>
    </section>

    <section [ngClass]="displayFieldCss('useCase')" *ngIf="feedBackForm.value.type === 'Enhancement'">
      <label for="useCase">Use Case</label>
      <textarea id="useCase" matInput formControlName="useCase" rows="4" placeholder="How this enhancement would be used / what the benefits are"></textarea>
    </section>

    <section [ngClass]="displayFieldCss('attachments')">
      <label for="attachments">Attachments: (max ~20mb total of all files)</label>
      <input id="attachments" type="file" (change)="onFileChange($event)" formControlName="attachments" formControlName="attachments" multiple>
      <p>File Size(s) exceed 20mb.</p>
    </section>

    <div>
      <button mat-button type="submit">Submit</button>
      <button mat-button type="button" class="cancel-button" (click)="closeFeedback()">Cancel</button>
    </div>

  </form>
  <div class="error" *ngIf="error">
    <h2>We're Sorry,</h2>
    <p>An error has occurred trying to process your request.</p>
    <p>This error may be temporary so you may wish to retry the submission in a few minutes.</p>
    <p>If the error continues to occur please contact <a href="mailto:EnergyAnalyticsSupport@enverus.com">EnergyAnalyticsSupport@enverus.com</a> directly.</p>
    <p>Thank you.</p>
  </div>
  <div class="success" *ngIf="success">
    <h2>Thank you!</h2>
    <p>We have received your submission and will be processing it shortly. You may receive additional communication regarding this issue in the future if warranted.</p>
  </div>
  <div *ngIf="success || error">
    <button mat-button class="close" (click)="closeFeedback()">Close Feedback</button>
  </div>
</div>
