<ng-container *ngIf="emailPreferences">
  <h1>
    <mat-icon>email</mat-icon>
    Email Preferences
    <button type="button" class="back" (click)="routeToDashboard()" matTooltip="Return to Dashboard">
      <mat-icon svgIcon="back"></mat-icon>
    </button>
  </h1>
  <strong>Important note:</strong>
  <p>By opting out you understand you will not receive investment research report email notifications as research is
    released. You will be solely responsible for accessing the research portal to view investment research report
    materials.</p>
  <form [formGroup]="emailPreferences" (ngSubmit)="onSubmit()" novalidate>
    <div formGroupName="globalUnsubscribe" class="checkbox-group vertical">
      <label>
        <mat-checkbox formControlName="global" [value]="globalSubscription.toString()"
                      [checked]="globalSubscription">Receive Enverus Intelligence® Vault Emails (customized below)
        </mat-checkbox>
      </label>
      <p><strong>Warning:</strong> By un-selecting this checkbox you are opting out of <strong><i>all</i></strong> Enverus Intelligence® Vault related mailing lists; including Activity Maps, Announcements, Energy Market Wraps, Morning
        Energy highlights, Podcasts, Presentations, Prism Signals, and Research - regardless of your previous group
        email preferences.</p>
      <p>If you wish to subscribe to any Vault related Enverus emails this checkbox must be checked (opt-in)</p>
    </div>
    <div formGroupName="suppressionGroup" class="checkbox-group vertical" *ngIf="globalSubscription" class="suppression-groups">
      <div class="suppression-container">
        <h2 class="header-with-buttons">
          Research:
          <div class="button-group">
            <button mat-button type="button" (click)="selectAll(suppressionList, 'Research: ')">Select All</button>
            <button mat-button type="button" (click)="selectNone(suppressionList, 'Research: ')">Select None</button>
          </div>
        </h2>
        <ng-container *ngFor="let suppression of suppressionList">
          <ng-container *ngIf="suppression && suppression.description.includes('Research: ')">
            <label *ngIf="!suppressOptIn(suppression.name)" class="clickableCheckboxArea">
              <mat-checkbox [formControlName]="suppression.id" [value]="suppression.suppressed"
                            [checked]="suppression.suppressed" (change)="markIfDirty()">
                {{ suppression.description.substr(10) }}
              </mat-checkbox>
            </label>
          </ng-container>
        </ng-container>
      </div>
      <div class="suppression-container">
        <h2 class="header-with-buttons">
          Publications:
          <div class="button-group">
            <button mat-button type="button" (click)="selectAll(suppressionList, 'Publication: ')">Select All</button>
            <button mat-button type="button" (click)="selectNone(suppressionList, 'Publication: ')">Select None</button>
          </div>
        </h2>
        <ng-container *ngFor="let suppression of suppressionList">
          <ng-container *ngIf="suppression && suppression.description.includes('Publication: ')">
            <label *ngIf="!suppressOptIn(suppression.name)" class="clickableCheckboxArea">
              <mat-checkbox [formControlName]="suppression.id" [value]="suppression.suppressed"
                            [checked]="suppression.suppressed" (change)="markIfDirty()">
                {{ suppression.description.substr(13) }}
              </mat-checkbox>
            </label>
          </ng-container>
        </ng-container>
      </div>
      <div class="suppression-container">
        <h2 class="header-with-buttons">
          Global:
          <div class="button-group">
            <button mat-button type="button" (click)="selectAll(suppressionList, 'Global: ')">Select All</button>
            <button mat-button type="button" (click)="selectNone(suppressionList, 'Global: ')">Select None</button>
          </div>
        </h2>
        <ng-container *ngFor="let suppression of suppressionList">
          <ng-container *ngIf="suppression && suppression.description.includes('Global: ')">
            <label *ngIf="!suppressOptIn(suppression.name)" class="clickableCheckboxArea">
              <mat-checkbox [formControlName]="suppression.id" [value]="suppression.suppressed"
                            [checked]="suppression.suppressed" (change)="markIfDirty()">
                {{ suppression.description.substr(7) }}
              </mat-checkbox>
            </label>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <button mat-button type="submit" [disabled]="emailPreferences?.pristine || isSaving">Save email preferences</button>
    <button *ngIf="isSaving" mat-button type="button" class="is-saving">Saving email preferences...</button>

    <div class="suppression-container">
      <h1 class="header-with-buttons">
        <img alt="Enverus Instant Analyst"
             ngSrc="/assets/icons/Enverus_GenAI_Icon-03%201.svg"
             class="iv-chatbot-message__chatbot-icon"
             height="32" width="32"/>
        Instant Analyst
        <div class="button-group">
          <button mat-button type="button" class="ia-terms" (click)="showIATerms()">Show Terms and Conditions</button>
        </div>
      </h1>
    </div>

  </form>

  <p style="margin-top: 20px">Return to Dashboard
    <button type="button" class="back" (click)="routeToDashboard()" matTooltip="Return to Dashboard">
      <mat-icon svgIcon="back"></mat-icon>
    </button>
  </p>
</ng-container>
<ng-container *ngIf="!emailPreferences">
  <iv-inline-loading text="Loading preferences..." [loaderStyle]="{background:'transparent'}" [iconStyle]="{width: '30px', height: '30px'}"></iv-inline-loading>
</ng-container>
