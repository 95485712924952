import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Location, LocationStrategy, NgIf} from '@angular/common';
import {OriginalUrlService} from "../../services/original-url/original-url.service";
import {UserService} from "../../services/user/user.service";

@Component({
  selector: 'iv-four-o-four-alertid-page',
  standalone: true,
  templateUrl: './four-o-four-alertid-page.component.html',
  styleUrls: ['./four-o-four-alertid-page.component.scss'],
  imports: [
    NgIf
  ],
  encapsulation: ViewEncapsulation.None
})
export class FourOFourAlertidPageComponent implements OnInit {
  public attemptedUrl: string = '';
  alertId: string | null = null;
  profile: any;
  public dateTimeNow: string = new Date().toISOString();

  constructor(private router: Router, private route: ActivatedRoute, private location: Location,
              private locationStrategy: LocationStrategy, private originalUrlService: OriginalUrlService,
              private userService: UserService) {
    this.profile = this.userService.getUserProfile();
  }

  ngOnInit(): void {
    this.alertId = this.route.snapshot.paramMap.get('alertId');
    this.attemptedUrl = this.originalUrlService.getOriginalUrl();
  }

}
