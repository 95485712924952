import {Component, Inject, OnDestroy, PLATFORM_ID, Renderer2, ViewEncapsulation} from '@angular/core';
import {CommonModule, isPlatformServer} from '@angular/common';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {MatMenuModule} from '@angular/material/menu';
import {IvauthService} from '../../services/ivauth/ivauth.service';
import {UserService} from '../../services/user/user.service';
import {RouterModule} from '@angular/router';
import {LogService} from '../../services/log/log.service';
import {FeedbackComponent} from '../../dialogs/feedback/feedback/feedback.component';
import {AuthService} from '@auth0/auth0-angular';

@Component({
  selector: 'iv-navbar',
  standalone: true,
  imports: [CommonModule, MatMenuModule, RouterModule],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnDestroy {
  isAuthenticated$ = this.auth.isAuthenticated$;
  menuopen: boolean = false;
  profileSubscription: Subscription;
  profile: any;
  zoomBotOpen: boolean = false;
  showHelp: boolean = false; // this is to control showing the icon when an adblocker is blocking it.

  constructor(public ivauth: IvauthService, private userService: UserService, private dialog: MatDialog, private logService: LogService, private renderer: Renderer2, private auth: AuthService, @Inject(PLATFORM_ID) private platformId: object) {
    this.profile = this.userService.getUserProfile();
    this.profileSubscription = this.userService.userProfile.subscribe(profile => {
      this.profile = profile;
    });

    if (!isPlatformServer(this.platformId)) {
      if ((window as any).zoomCampaignSdk) {
        this.renderer.addClass(this.renderer.selectRootElement('iv-navbar', true), 'zoombot');
        this.showHelp = true;
      } else {
        window.addEventListener('zoomCampaignSdk:ready', () => {
          this.renderer.addClass(this.renderer.selectRootElement('iv-navbar', true), 'zoombot');
          this.showHelp = true;
        });
      }
    }

  }

  logRudderstackEvent(event: string) {

    if (event === 'Gallery') {
      this.logService.track('gallery_logo_viewed', false, {});
    } else {
      if (event === 'Dashboard') {
        this.logService.track('intelligence_logo_viewed', false, {});
      }
    }

  }

  showFeedback(actions?: any) {
    //window['rsResearchFeedback']();

    let dialogRef = this.dialog.open(FeedbackComponent, {
      panelClass: 'iv-feedback',
      disableClose: false,
      autoFocus: true,
      width: '600px',
      height: 'auto',
      data: actions
    });
  }

  showZoomBot() {
    if (!isPlatformServer(this.platformId)) {
      if ((window as any).zoomCampaignSdk) {
        (window as any).zoomCampaignSdk.on('open', () => {
          this.zoomBotOpen = true;
        });
        (window as any).zoomCampaignSdk.on('close', () => {
          this.zoomBotOpen = false;
        });
        if (!this.zoomBotOpen) {
          (window as any).zoomCampaignSdk.open();
        } else {
          (window as any).zoomCampaignSdk.close();
        }
      }
    }
  }

  ngOnDestroy() {
    this.profileSubscription.unsubscribe();
  }

}
