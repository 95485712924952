import { Injectable } from '@angular/core';

import { Observable, ReplaySubject } from "rxjs";

import { Constants } from "../../constants/constants";
import { SnackbarArticle } from "../../interface/snackbararticles";

@Injectable({
  providedIn: 'root'
})
export class SnackbarArticlesService {

  private articles: SnackbarArticle[] = [];
  private ignoreArticles: SnackbarArticle[] = [];

  constructor() {
  }

  private removeExpiredIgnoreArticles(): void {
    if(this.ignoreArticles.length) {
      let _ignoreArticles: SnackbarArticle[] = [];
      let cutoffDate: number = Date.now() - (Constants.snackbarArticlesPublishedSeconds * 1000);
      this.ignoreArticles.forEach(function (article: SnackbarArticle) {
        let pubDate: number = new Date(article.publishedDate).getTime();
        if (pubDate > cutoffDate) {
          _ignoreArticles.push(article);
        }
      });
      this.ignoreArticles = _ignoreArticles;
    }
  }

  private expireArticle(id: string): void {
    const _self = this;
    this.removeExpiredIgnoreArticles();
    let _articles: SnackbarArticle[] = [];
    this.articles.forEach(function (article: SnackbarArticle) {
      if(article.id !== id) {
        _articles.push(article);
      } else {
        let ignoreIndex: number = _self.ignoreArticles.findIndex(ignoreArticle => ignoreArticle.id === id);
        if(ignoreIndex < 0) {
          _self.ignoreArticles.push(article);
        }
      }
    });
    this.articles = _articles;
  }

  public expireAllArticles(): void {
    const _self = this;
    this.removeExpiredIgnoreArticles();
    this.articles.forEach(function (article: SnackbarArticle) {
      let ignoreIndex: number = _self.ignoreArticles.findIndex(ignoreArticle => ignoreArticle.id === article.id);
      if(ignoreIndex < 0) {
        _self.ignoreArticles.push(article);
      }
    });
    this.articles = [];
  }

  // passing empty array valid as this method also removes outdated articles
  addUpdateArticles(newArticles: SnackbarArticle[]): void {
    const _self = this;

    // add any new (unique)
    newArticles.forEach(function (newArticle: SnackbarArticle) {

      if (_self.articles.find(article => article.id === newArticle.id) === undefined) {

        // only allow adding article if not on ignore list
        let ignoreIndex: number = _self.ignoreArticles.findIndex(ignoreArticle => ignoreArticle.id === newArticle.id);
        if(ignoreIndex < 0) {
          _self.articles.push(newArticle);
        }

      }
    });

    // sort the array in descending publishedDate order (newest first)
    this.articles.sort((a, b) => b.publishedDate - a.publishedDate);
  }

  getNextArticle(): SnackbarArticle | null {
    let retval = null;

    if(this.articles.length) {
      retval = JSON.parse(JSON.stringify(this.articles[0]));
      this.expireArticle(this.articles[0].id);
    }

    return retval;
  }

  dismissArticle(id: string, removeFromArticles: boolean = true): void {

    // if article found
    let ignoreArticle = this.articles.find(article => article.id === id);
    if( ignoreArticle !== undefined) {

      this.removeExpiredIgnoreArticles();

      // add the article to be ignored
      this.ignoreArticles.push(ignoreArticle);

      // remove it from the articles list
      if(removeFromArticles) {
        let _articles: SnackbarArticle[] = [];
        this.articles.forEach(function (article: SnackbarArticle) {
          if ((ignoreArticle === undefined) || (article.id !== ignoreArticle.id)) {
            _articles.push(article);
          }
        });
        this.articles = _articles;
      }

    }

  }

}
