import {
  Component,
  inject, Input,
  OnChanges,
  OnDestroy,
  OnInit, SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";

import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";

import { Observable, Subscription } from "rxjs";
import { v4 as uuidv4 } from 'uuid';
import { MomentModule } from 'ngx-moment';

import { NgxPaginationModule } from "ngx-pagination";
import { NgPipesModule } from "ngx-pipes";
import { NgProgress, NgProgressRef } from 'ngx-progressbar';

import {Constants} from "../../constants/constants";

import { AzureSearchService } from "../../services/azuresearch/azuresearch.service";
import { DashboardService } from "../../services/dashboard/dashboard.service";
import { DataService } from "../../services/data/data.service";
import { LogService } from '../../services/log/log.service';
import { SearchService } from "../../services/search/search.service";
import { SharedService } from "../../services/shared/shared.service";
import { UserService } from "../../services/user/user.service";

import {AzssError, AzssStore} from "../../interface/azss";
import { ResearchStore } from "../../store/research-store/research.store";
import { Research } from "../../interface/research";

import { CeilPipe } from "../../pipes/ceil/ceil.pipe";
import { FileSizePipe } from "../../pipes/filesize/filesize.pipe";
import { MomentDatePipe } from "../../pipes/momentDate/momentDate.pipe";

@Component({
  selector: 'iv-downloads-view',
  standalone: true,
    imports: [
        CommonModule,
        MatCheckboxModule,
        MatIconModule,
        MatTooltipModule,
        NgxPaginationModule,
        NgPipesModule,
        RouterModule,
        CeilPipe,
        FileSizePipe,
        MomentDatePipe,
        MatChipsModule,
        MomentModule,
    ],
  templateUrl: './downloads-view.component.html',
  styleUrls: ['./downloads-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DownloadsViewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() store: any;

  public azureSearchService = inject( AzureSearchService );
  public userService = inject( UserService );

  research: Research[] = [];
  researchItem: Research | undefined;
  researchSubscription: Subscription = Subscription.EMPTY;

  downloadlink: any;

  queuedDownloads: any = null;
  queuedDownloadsCount: number = 0;
  downloadQueueTooltip: string = '';
  showBulkDownloadMsg: boolean = false;

  selectedItem: any;

  // if more than one seperate instance of ngx-pagination on a page (ie: reports / livefeed component)
  // then angular breaks BADLY... throwing hundreds of errors a second
  // Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
  // ensure each instance (component) has a unique pagination id: <pagination-controls id="{{paginationId}}" ...
  // which must be referenced by ngFor: <ng-container *ngFor="let azrec of azss.results?.results | paginate: { id: paginationId, ...
  paginationId: string = uuidv4();

  reportsChipPrettyTextSubscription: Subscription = Subscription.EMPTY;
  chipPrettyText: string = '';

  reportsFacetsPrettyTextSubscription: Subscription = Subscription.EMPTY;
  facetsPrettyText: string = '';

  progressRef: NgProgressRef;                                    // progress spinner while ajax calls loading

  subscriptionRequiredMessage: string = Constants.subscriptionRequiredMessage;

  constructor(
    private readonly _researchStore: ResearchStore,
    private ngProgress: NgProgress,
    public dashboardService: DashboardService,
    private dataService: DataService,
    private logService: LogService,
    public searchService: SearchService,
    private sharedService: SharedService,
  ) {

    this.progressRef = this.ngProgress.ref();

    // subscribe to store for ui data per research collection
    this.researchSubscription = this.searchService.research$.subscribe((research) => {
      this.research = research;
      if(this.store) {
        let newResearchItem: Research | undefined = this.research.find(rec => rec.activeStoreKey === this.store.collection);
        if(newResearchItem !== undefined) {
          // if(JSON.stringify(newResearchItem) !== JSON.stringify(this.researchItem)) {
            this.researchItem = JSON.parse(JSON.stringify(newResearchItem));
          // }
        }
      }
    });

  }

  ngOnInit(): void {

    this.reportsChipPrettyTextSubscription = this.sharedService.reportsChipPrettyText$.subscribe( (text: string) => {
      this.chipPrettyText = text;
    });

    this.reportsFacetsPrettyTextSubscription = this.sharedService.reportsFacetsPrettyText$.subscribe( (text: string) => {
      this.facetsPrettyText = text;
    });

  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['store']) {
      let newResearchItem: Research | undefined = this.research.find(rec => rec.activeStoreKey === this.store.collection);
      if(newResearchItem !== undefined) {
        this.researchItem = JSON.parse(JSON.stringify(newResearchItem));
      }
    }

  }

  public navigateToPage(q: string | undefined, page: number) {
    this.searchService.setPage(this.store.collection, page);
    this.searchService.search(this.store.collection).then((azssError: AzssError | null) => {
    }).catch((error: any) => {
    });
  }

  public downloadAttachment(id: string, model: string) {
    this.dataService.getAttachments(id.toString())
      .subscribe(data => {
        if (data.url.length !== 0) {
          let file = data.url.filter((x: any) => x.azure_url_filename === model)[0].azure_url;
          this.downloadlink = document.createElement('A');
          this.downloadlink.href = file;
          let filename = data.url.filter((x: any) => x.azure_url_filename === model)[0].azure_url_filename;  // BUG?
          this.downloadlink.download = filename;
          document.body.appendChild(this.downloadlink);
          this.downloadlink.click();
          document.body.removeChild(this.downloadlink);

          this.logService.article = data.result;
          this.logService.track("attachment_downloaded", true,{
            article_filename: filename
          });

        }
      }, err => {
      });
  }

  public calcAge(date: any) {
    const time = (Date.now() - Date.parse(date)) / 1000;
    switch (true) {
      case (time < 1800) :
        return 'thirtymin';
      case (time < 14400) :
        return 'fourhour';
      case (time < 28800) :
        return 'today';
      default:
    }
  }

  public queueAll($event: any) {
    this.queuedDownloads = [];
    this.showBulkDownloadMsg = false;
    this.downloadQueueTooltip = '';
    if ($event.checked) {
      if(this.store.results) {
        for (let result of this.store.results.results) {
          if (result.permitted) {
            this.queuedDownloads.push(result);
          }
        }
      }
    }
    this.createTooltip();
    // this.cdr.detectChanges();
  }

  public toggleQueued(result: any) {
    if (!this.queuedDownloads) {
      this.queuedDownloads = [];
    }
    if (!this.queuedDownloads.find((qd: any) => qd.id == result.id)) {
      this.queuedDownloads.push(result);
    } else {
      let index = this.queuedDownloads.findIndex((qd: any) => qd.id == result.id);
      this.queuedDownloads.splice(index, 1);
    }
    this.createTooltip();
  }

  public isQueued(id: any) {
    if (!this.queuedDownloads) {
      this.queuedDownloads = [];
    }
    if (this.queuedDownloads.find((qd: any) => qd.id == id)) {
      return true;
    }
  }

  public downloadQueueSize() {
    if (!this.queuedDownloads) {
      this.queuedDownloads = [];
    }
    return this.queuedDownloads.length;
  }

  public bulkDownloadQueue() {

    // if only 1 file queued, then shunt them directly to downloadAttachment() !
    if(this.queuedDownloads.length === 1) {
      this.downloadAttachment(this.queuedDownloads[0].parentId, this.queuedDownloads[0].filename);
      this.queuedDownloads = [];

    // otherwise proceed with the bulk download system
    } else {

      this.showBulkDownloadMsg = true;
      this.progressRef = this.ngProgress.ref();
      this.progressRef.start();
      setTimeout(() => {
        this.removeEmailChip();
      }, 10000); // 10 seconds in milliseconds
      this.dataService.getBulkFiles(JSON.stringify(this.queuedDownloads)).then((results) => {

        let multiArticle: boolean = false;
        let parentId: string = '';
        let fileList: string[] = [];
        this.queuedDownloads.forEach(function (attachment: any) {
          if (attachment.hasOwnProperty('filename') && attachment.filename.length) {
            fileList.push(attachment.filename);
          }
          if (!parentId.length) {
            parentId = attachment.parentId;
          } else {
            if (attachment.parentId !== parentId) {
              multiArticle = true;
            }
          }
        });

        this.logService.track("bulk_file_requested", false, {
          files_downloaded: fileList,
          multi_article: multiArticle
        });

        this.queuedDownloads = [];
        this.progressRef.complete();
      });
    }
  }

  private createTooltip() {
    let tooltip = `${this.queuedDownloads.length} Files Queued for Download:\n`;
    let total = 0;
    this.queuedDownloads.forEach(function (download: any) {
      let filesize = Math.round((download.filesize / 1000000) * 100) / 100;
      total += filesize;
      tooltip += `${download.filename} : ${filesize} MB\n`;
    });
    total = Math.round(total * 100) / 100;
    tooltip += `Total uncompressed: ${total} MB`;
    this.downloadQueueTooltip = tooltip;
  }

  public clearDownloadQueue() {
    this.queuedDownloads = [];
    this.downloadQueueTooltip = '';
  }

  public removeChip(): void {
    this.dashboardService.clearAllFilters(this.store.collection);
    this.searchService.ss_set_collection(this.store.collection);
  }

  public activateChip(): void {
    this.dashboardService.updateShowReportFacets(true);
  }

  public removeEmailChip(): void {
    this.showBulkDownloadMsg = false;
  }

  ngOnDestroy() {
    this.researchSubscription.unsubscribe();
    this.reportsChipPrettyTextSubscription.unsubscribe();
    this.reportsFacetsPrettyTextSubscription.unsubscribe();
  }

}
