<article>
  <h1>We're sorry, you are unauthorized to view this page.</h1>
  <br/>
  <h2>URL: <a href="{{attemptedUrl}}">{{ attemptedUrl }}</a></h2>
  <p *ngIf="profile && profile.email">Email: {{profile.email}}</p>
  <p>Date/time: {{dateTimeNow}} (GMT)</p>
  <br/>
  <p>The Enverus Intelligence Vault is based on a tiered subscription model and you do not have the necessary authorization to view this page.</p>
  <p>If Enverus Customer Care recently updated your account please logout and log back in for your entitlements to update.</p>
  <p>If you believe this is an error, or you wish to upgrade your subscription, please contact your account representative or email <a href="mailto:EnergyAnalyticsSupport@enverus.com">EnergyAnalyticsSupport@enverus.com</a> for assistance.</p>
</article>
