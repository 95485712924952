import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from "@angular/material/dialog";

@Component({
  selector: 'iv-livefeed-nav-help',
  standalone: true,
  imports: [CommonModule, MatDialogModule],
  templateUrl: './livefeed-help.component.html',
  styleUrls: ['./livefeed-help.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LivefeedHelpComponent {

}
