import { Component, Input, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

import { interval, Observable, Subscription } from "rxjs";

import { SearchService } from "../../services/search/search.service";

import { LivefeedViewComponent } from "../../views/livefeed-view/livefeed-view.component";
import { ReportsViewComponent } from "../../views/reports-view/reports-view.component";

@Component({
  selector: 'iv-new-research',
  standalone: true,
  imports: [CommonModule, ReportsViewComponent, LivefeedViewComponent],
  templateUrl: './new-research.component.html',
  styleUrls: ['./new-research.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewResearchComponent {
  @Input() store: any;

  constructor(
    public searchService: SearchService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

}
