import {Component, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location, LocationStrategy, NgIf} from '@angular/common';
import {UserService} from "../../services/user/user.service";
import {User} from "@auth0/auth0-angular";

@Component({
  standalone: true,
  selector: 'iv-four-o-three-page',
  templateUrl: './four-o-three-page.component.html',
  styleUrls: ['./four-o-three-page.component.scss'],
  imports: [
    NgIf
  ],
  encapsulation: ViewEncapsulation.None
})
export class FourOThreePageComponent {
  public attemptedUrl = '';
  profile: any;
  public dateTimeNow: string = new Date().toISOString();

  constructor(private route: ActivatedRoute, private router: Router, private location: Location, private locationStrategy: LocationStrategy,
              private userService: UserService) {
    this.profile = this.userService.getUserProfile();
    this.route.queryParams.subscribe(params => {
      this.attemptedUrl = params['attemptedUrl'] || this.router.url;
    });
  }

}
