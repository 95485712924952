<section class="search-results">
  <ng-container *ngIf="store && ( (((store.results?.results?.length ?? 0) < 1) && (store.collection !== 'Bookmarks')) || store.results?.noResults)">
    <section class="noresults">
      <ng-container *ngIf="(store.collection === 'Bookmarks')">
        <ng-container *ngIf="favoritesDb && !favoritesDb.length">
          <h2>Bookmarks</h2>
          <p class="bookmark-text">To add a bookmark click on the bookmark icon
            <mat-icon>bookmark_add</mat-icon>
            either in the search results or on the detailed article screen.
          </p>
        </ng-container>
        <ng-container *ngIf="favoritesDb && favoritesDb.length">
          <h2>Bookmarks</h2>
          <p class="bookmark-text">Your search criteria matches 0 of your {{favoritesDb.length}} bookmark(s).
          </p>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="(store.collection !== 'Bookmarks') && !store.results?.firstResultsFetched">
        <iv-inline-loading text="Loading results..." [loaderStyle]="{background:'transparent'}" [iconStyle]="{width: '30px', height: '30px'}"></iv-inline-loading>
      </ng-container>
      <ng-container *ngIf="(store.collection !== 'Bookmarks') && store.results?.firstResultsFetched">
        <h2>Your search returned no results.</h2>
        <p>Suggestions:</p>
        <ul>
          <li *ngIf="(researchItem !== undefined) && (researchItem.chipContents !== undefined) && (researchItem.chipContents.length > 0)">Clear active filters:
            <ng-container *ngIf="(researchItem !== undefined) && (researchItem.chipContents !== undefined) && (researchItem.chipContents.length > 0)">
              <p class="chip" *ngIf="(researchItem !== undefined) && (researchItem.chipContents !== undefined) && (researchItem.chipContents.length > 0)">
                <svg *ngIf="(researchItem !== undefined) && (researchItem.chipContents !== undefined) && (researchItem.chipContents.length > 0)" id="uuid-4c73457f-24eb-4332-a564-e0f2b0e909f9" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" height="16px">
                  <defs>
                    <style>.uuid-2c6c7e4c-3c2e-4d6a-bd53-d8a0b5baa376 {
                      fill: #58b947;
                      stroke-width: 0px;
                    }</style>
                  </defs>
                  <path class="uuid-2c6c7e4c-3c2e-4d6a-bd53-d8a0b5baa376" d="m89.26,3.72H14.29c-1.51,0-2.38,1.72-1.49,2.94l29.75,40.5c.23.32.36.7.36,1.09v48.03h15.06v-47.99c0-.42.14-.83.4-1.15L90.7,6.72c.97-1.21.11-3-1.44-3Z"/>
                </svg>
                <mat-chip-option *ngIf="(researchItem !== undefined) && (researchItem.chipContents !== undefined) && (researchItem.chipContents.length > 0)" (removed)="removeChip()" class="chip-contents" matTooltip="{{facetsPrettyText}}">
                  {{chipPrettyText| shorten : 150 : "..." }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-option>
              </p>
            </ng-container>
          </li>
          <li>Make sure that all words are spelled correctly.</li>
          <li>Try different keywords.</li>
          <li>Try more general keywords.</li>
          <li>Try fewer keywords.</li>
          <li>Try different filters.</li>
        </ul>
      </ng-container>
    </section>
  </ng-container>
  <ng-container *ngIf="store.collection !== 'ReportsNew'">
    <ng-container *ngIf="store && ((store.results?.results?.length ?? 0) >= 1) && !store.results.noResults">
      <ng-container *ngIf="(store.collection !== 'Bookmarks') && !store.results?.firstResultsFetched">
        <iv-inline-loading text="Loading results..." [loaderStyle]="{background:'transparent'}" [iconStyle]="{width: '30px', height: '30px'}"></iv-inline-loading>
      </ng-container>
      <ng-container *ngIf="store.results?.firstResultsFetched">
        <section class="result-header">
          <p class="results"><strong>{{store.results!.count!}}</strong> results, page <strong>{{store.results!.page}}</strong>
            of <strong>{{ (store.results!.count / store.results!.perPage) | ceil }}</strong>
                    sorted by <strong> {{(store.parameters!.searchParameters!.orderBy[0] === 'publishedDate desc') ?
                      'Date Descending' : (store.parameters!.searchParameters!.orderBy[0] === 'publishedDate asc') ? 'Date Ascending' : 'Relevance'}}</strong>.
                    {{store.results?.count! >= 100000 ? 'Maximum 100000 results reached... Please refine your search.' : ''}}
          </p>
          <ng-container *ngIf="(researchItem !== undefined) && (researchItem.chipContents !== undefined) && (researchItem.chipContents.length > 0)">
            <div *ngIf="(researchItem !== undefined) && (researchItem.chipContents !== undefined) && (researchItem.chipContents.length > 0)" class="chip-container centered-content" matTooltip="{{facetsPrettyText}}">
              <svg *ngIf="(researchItem !== undefined) && (researchItem.chipContents !== undefined) && (researchItem.chipContents.length > 0)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <circle cx="256" cy="256" r="240.845" fill="#f3f6f7" stroke="#57b947" stroke-width="23.52" paint-order="normal"/>
                <path d="M370.252 114.939H141.743c-4.602 0-7.254 5.243-4.542 8.961l90.678 123.444c.7.976 1.097 2.134 1.097 3.322v146.395h45.903V250.788c0-1.28.426-2.53 1.219-3.505l98.542-123.2c2.956-3.688.335-9.144-4.389-9.144z" fill="#58b947"/>
              </svg>
              <mat-chip-option *ngIf="(researchItem !== undefined) && (researchItem.chipContents !== undefined) && (researchItem.chipContents.length > 0)" [disableRipple]=true (click)="activateChip()" class="chip-contents">
                {{chipPrettyText| shorten : 150 : "..." }}
              </mat-chip-option>
              <button (click)="removeChip()" class="close-chip">
                <mat-icon>cancel</mat-icon>
              </button>
            </div>
          </ng-container>
          <pagination-controls id="{{paginationId}}" (pageChange)="navigateToPage(store.parameters?.input, $event)" [maxSize]="0" class="top"></pagination-controls>
        </section>
        <ng-container *ngFor="let azrec of store.results?.results | paginate: { id: paginationId, itemsPerPage: store.results?.perPage, currentPage: store.results?.page, totalItems: store.results?.count }">
          <section [ngClass]="{'active': selectedItem == azrec}" (mouseenter)="setActive($event, azrec)" (mouseleave)="setActive($event, false)">
            <article [ngClass]="calcAge(azrec.publishedDate)">
              <ng-container *ngIf="azrec.permitted">
                <div class="eir">
                  <h2><a routerLink="/research/{{azrec.id}}">{{azrec.title}}</a></h2>
                  <span *ngIf="azrec.intelligenceType == 'Research'" data-alttext="EIR"><span>Enverus Intelligence</span><span class="trademark">®</span><span> | Research</span></span>
                </div>
              </ng-container>
              <ng-container *ngIf="!azrec.permitted">
                <h2 matTooltip="**You do not have the necessary entitlement to view this content. Please contact your account representative or email EnergyAnalyticsSupport@enverus.com for assistance." matTooltipPosition="below">{{azrec.title}}</h2>
              </ng-container>
              <p>{{azrec.descriptionText | shorten : 300 : "..." }}</p>
              <footer>
                <div class="actions">
                  <p>
                    <span class="publish-date">{{azrec.publishedDate | amTimeAgo}}</span>
                    <span><strong>{{azrec.series}}</strong></span>
                    <span *ngIf="azrec.category && azrec.category.length > 0">{{azrec.category.join(", ")}}</span>
                    <span *ngIf="azrec.subCategory && azrec.subCategory.length > 0">{{azrec.subCategory.join(", ")}}</span>
                    <span *ngIf="azrec.countries && azrec.countries.length > 0">{{azrec.countries.join(", ")}}</span>
                    <span *ngIf="azrec.regions && azrec.regions.length > 0">{{azrec.regions.join(", ")}}</span>
                    <span *ngIf="!isProduction">(search score {{(azrec['@search.score'])}})</span>
                  </p>
                  <button *ngIf="azrec.permitted" (click)="toggleFavorite(azrec)" matTooltip="{{isFavorite(azrec.id) ? 'Remove from Bookmarks' : 'Add to Bookmarks'}}">
                    <ng-container *ngIf="isFavorite(azrec.id)">
                      <mat-icon>bookmark_remove</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="!isFavorite(azrec.id)">
                      <mat-icon>bookmark_add</mat-icon>
                    </ng-container>
                  </button>
                </div>
              </footer>
            </article>
          </section>
        </ng-container>
        <section class="result-footer">
          <p class="results"><strong>{{store.results!.count!}}</strong> results, page <strong>{{store.results!.page}}</strong>
            of <strong>{{ (store.results!.count / store.results!.perPage) | ceil }}</strong>
            sorted by <strong> {{(store.parameters!.searchParameters!.orderBy[0] === 'publishedDate desc') ?
              'Date Descending' : (store.parameters!.searchParameters!.orderBy[0] === 'publishedDate asc') ? 'Date Ascending' : 'Relevance'}}</strong>.
            {{store.results?.count! >= 100000 ? 'Maximum 100000 results reached... Please refine your search.' : ''}}
          </p>
          <pagination-controls id="{{paginationId}}" (pageChange)="navigateToPage(store.parameters?.input, $event)" [maxSize]="0" class="top"></pagination-controls>
        </section>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="store.collection==='ReportsNew'">
    <ng-container *ngIf="store && (store.results?.results?.length ?? 0) >= 1">
      <section class="result-header">
        <p class="results"><strong>{{store.results!.count!}}</strong> results, page <strong>{{store.results!.page}}</strong>
          of <strong>{{ (store.results!.count / store.results!.perPage) | ceil }}</strong>
          sorted by <strong> {{(store.parameters!.searchParameters!.orderBy[0] === 'publishedDate desc') ?
            'Date Descending' : (store.parameters!.searchParameters!.orderBy[0] === 'publishedDate asc') ? 'Date Ascending' : 'Relevance'}}</strong>.
          {{store.results?.count! >= 100000 ? 'Maximum 100000 results reached... Please refine your search.' : ''}}
        </p>
      </section>
      <ng-container *ngFor="let azrec of store.results?.results">
        <section [ngClass]="{'active': selectedItem == azrec}" (mouseenter)="setActive($event, azrec)" (mouseleave)="setActive($event, false)">
          <article>
            <ng-container *ngIf="azrec.permitted">
              <div class="eir">
                <h2><a routerLink="/research/{{azrec.id}}">{{azrec.title}}</a></h2>
                <span *ngIf="azrec.intelligenceType == 'Research'" matTooltip="Enverus Intelligence® | Research">EIR</span>
              </div>
            </ng-container>
            <ng-container *ngIf="!azrec.permitted">
              <h2 matTooltip="**You do not have the necessary entitlement to view this content. Please contact your account representative or email EnergyAnalyticsSupport@enverus.com for assistance." matTooltipPosition="below">{{azrec.title}}</h2>
            </ng-container>
            <p>{{azrec.descriptionText | shorten : 300 : "..." }}</p>
            <footer>
              <div class="actions" *ngIf="azrec.permitted">
                <button [ngClass]="isFavorite(azrec.id) ? 'icon-favorite-on' : 'icon-favorite-off'" (click)="toggleFavorite(azrec)" matTooltip="Remove from Bookmarks"></button>
              </div>
            </footer>
          </article>
        </section>
      </ng-container>
      <section class="result-footer">
        <p class="results"><strong>{{store.results!.count!}}</strong> results, page <strong>{{store.results!.page}}</strong>
          of <strong>{{ (store.results!.count / store.results!.perPage) | ceil }}</strong>
          sorted by <strong> {{(store.parameters!.searchParameters!.orderBy[0] === 'publishedDate desc') ?
            'Date Descending' : (store.parameters!.searchParameters!.orderBy[0] === 'publishedDate asc') ? 'Date Ascending' : 'Relevance'}}</strong>.
          {{store.results?.count! >= 100000 ? 'Maximum 100000 results reached... Please refine your search.' : ''}}
        </p>
      </section>
    </ng-container>
  </ng-container>
</section>
