import {ChangeDetectorRef, Component, ViewEncapsulation} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {DataService} from '../../../services/data/data.service';
import {UserService} from '../../../services/user/user.service';
import {MatDialog} from '@angular/material/dialog';
import {NotificationService} from '../../../services/notification/notification.service';
import {LogService} from '../../../services/log/log.service';
import {MatIconModule} from '@angular/material/icon';
import {Router} from '@angular/router';
import {InlineLoadingComponent} from '../../inline-loading/inline-loading.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ReportsHelpComponent} from "../../../dialogs/reports-help/reports-help.component";
import {ChatbotDisclosureComponent} from "../../../dialogs/chatbot-disclosure/chatbot-disclosure.component";
import {filter} from "rxjs/operators";

@Component({
  selector: 'iv-email-preferences',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatCheckboxModule, MatIconModule, InlineLoadingComponent, MatTooltipModule, NgOptimizedImage],
  templateUrl: './email-preferences.component.html',
  styleUrls: ['./email-preferences.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmailPreferencesComponent {
  profile: any;
  optInList: any;
  emailPreferences: any;
  globalSubscription = false;
  suppressionList: any;
  showEmailPreferences = true;
  isSaving: boolean = false;

  checkboxes: FormGroup = this.formBuilder.group({});

  constructor(private formBuilder: FormBuilder, private dataService: DataService, private userService: UserService, private cdr: ChangeDetectorRef, private dialog: MatDialog, private notificationService: NotificationService, private logService: LogService, private router: Router) {
  }

  ngOnInit() {
    this.updateForm();
  }

  updateForm() {
    this.profile = this.userService.getUserProfile();
    this.dataService.getUserSuppressionList(this.profile.email).subscribe({
      next: (data) => {
        const groupsToRemove: any[] = []; // removing old groups
        data[2].suppressions = data[2].suppressions.filter((ar:any) => !groupsToRemove.find(rm => (rm === ar.id)));
        this.updateOptInList(data[0]);
        this.globalSubscription = data[1];
        this.suppressionList = data[2].suppressions;
        this.suppressionList.sort((a: { name: number; }, b: { name: number; }) => {
          if (a.name < b.name) {
            return -1;
          } else {
            return 1;
          }
        });

        this.emailPreferences = this.formBuilder.group({
          globalUnsubscribe: this.formBuilder.group({
            global: this.globalSubscription
          }),
          suppressionGroup: this.formBuilder.group({})
        });

        this.suppressionList.forEach((item: any) => {
          this.checkboxes = <FormGroup>this.emailPreferences.get('suppressionGroup');
          this.checkboxes.addControl(item.id, new FormControl(!item.suppressed));
        });

        this.cdr.detectChanges();

        this.logService.track("preference_button_selected", false,{});

      },
      error: (e) => {

      },
    });
  }

  updateOptInList(sendgridProfile: any) {
    let list = [];
    let optInGroups = sendgridProfile.custom_fields.filter((o:any) => o.name.includes('optin_') && (o.value === null || o.value === 0));
    for (let group of optInGroups) {
      let name = group.name.substr(6).replaceAll('_', ' ').split(' ').map((w:any) => w[0].toUpperCase() + w.substring(1).toLowerCase()).join(' ');
      list.push({
        id: group.name,
        name: name
      });
    }
    if (list.length > 0) {
      this.optInList = list;
    }
    if (list.length === 0) {
      this.optInList = null;
    }
  }

  getFormDiff(): Array<any> {
    const globalUnsubscribe = JSON.parse(JSON.stringify(this.emailPreferences.value.globalUnsubscribe));
    // Values from form - true => getting emails
    const suppressions = this.emailPreferences.value.suppressionGroup;
    // Current 'is unsubscribed from' - true => emails suppressed
    let actions = JSON.parse(JSON.stringify(this.suppressionList));

    actions = actions.filter((list: any) => {
      if ((list.suppressed === suppressions[list.id])) {
        list.suppressed = !suppressions[list.id];
        return true;
      }
    });

    if (globalUnsubscribe['global'] !== this.globalSubscription) {
      actions.push(globalUnsubscribe);
    }

    return actions;
  }

  markIfDirty() {
    const actions = this.getFormDiff();
    if (actions.length > 0) {
      this.emailPreferences.markAsDirty();
      return;
    }
    this.emailPreferences.markAsPristine();
  }

  onSubmit() {
    const actions = this.getFormDiff();
    // return if we have no changes
    if (actions.length === 0) {
      this.emailPreferences.markAsPristine();
      return false;
    }

    this.updateUserSuppressionList(this.profile.email, actions);
  }

  updateUserSuppressionList(email: string, actions: any) {
    this.isSaving = true;
    // @ts-ignore
    actions = actions.map(({name, description, is_default, last_email_sent_at, ...item}) => item);
    this.dataService.updateUserSuppressionList(this.profile.email, {actions: actions, profile: this.profile}).subscribe(
      {
        next: (data) => {
          this.updateForm();
          this.notificationService.open('Email preferences saved', '', 3000, 'success')
            .afterDismissed()
            .subscribe(() => {
              this.isSaving = false;
            });
        },
        error: (e) => {
          this.isSaving = false;
          const err = this.notificationService.open('ERROR: Email preferences failed to save - please try again later', 'OK', 0, 'error');
          err.onAction().subscribe(() => {
            this.updateForm();
          });
        }
      }
    );
  }

  suppressOptIn(series: string) {
    if (this.optInList) {
      let test = this.optInList.find(
        (e:any) => e.name === series
      );
      return !!test;
    } else {
      return false;
    }
  }

  optIn(series: string) {
    this.dataService.optInToEmailSeries(series).subscribe((data) => {
      this.updateForm();
      this.userService.updateUserProfile();
      this.cdr.detectChanges();
      this.notificationService.open('Opt-In Preferences Saved', '', 3000, 'success');
    }, err => {
      this.notificationService.open('ERROR: Opt-In preferences failed to save - please try again later', 'OK', 0, 'error');
    });
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  public routeToDashboard(): void {
    this.router.navigate(['/dashboard'], {});
  }

  selectAll(list: any[], filter: string): void {
    list
      .filter((item) => item.description.includes(filter))
      .forEach((item) => this.checkboxes.controls[item.id].setValue(true));
    this.markIfDirty();  // test if checkboxes have changed and enable submit button if so
  }

  selectNone(list: any[], filter: string): void {
    list
      .filter((item) => item.description.includes(filter))
      .forEach((item) => this.checkboxes.controls[item.id].setValue(false));
    this.markIfDirty();  // test if checkboxes have changed and enable submit button if so
  }

  public showIATerms($event: any) {
    const dialogRef = this.dialog.open(ChatbotDisclosureComponent, {
      panelClass: 'iv-chatbot-disclosure-dialog',
      disableClose: false,
      autoFocus: true,
      width: '600px',
      height: 'auto',
      data: ''
    });
  }

}
