import {CanActivateFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {firstValueFrom} from 'rxjs';
import {UserService} from '../services/user/user.service';
import {IvauthService} from '../services/ivauth/ivauth.service';
import {environment} from '../../environments/environment';

export const authorizationGuard: CanActivateFn = async (route, state) => {
  const router = inject(Router);
  const userService: UserService = inject(UserService);
  const ivAuth: IvauthService = inject(IvauthService);

  const profile = userService.getUserProfile() || {};
  const permissions = userService.getUserPermissions() || {};

  if (!(isObjectEmpty(profile) && isObjectEmpty(permissions))) {
    if (environment.preview) {
      if (isEmailMatch(profile.email)) {
        return true;
      } else {
        router.navigate(['preview']);
        return false;
      }
    } else {
      return true;
    }
  } else {
    const authorized = await ivAuth.authorizeUser();
    if (authorized) {
      return true;
    } else {
      return router.createUrlTree(['/error/authorization'], {queryParams: {path: state.url}});
    }
  }
};

const isEmailMatch = (email: string) => {
  // Check if the email matches any of the domains
  if (environment.PREVIEW && environment.PREVIEW.DOMAINS) {
    for (const domain of environment.PREVIEW.DOMAINS) {
      if (email.endsWith(domain)) {
        return true;
      }
    }
  }

  if (environment.PREVIEW && environment.PREVIEW.USERS) {
    // Check if the email matches any of the users
    for (const user of environment.PREVIEW.USERS) {
      if (email === user) {
        return true;
      }
    }
  }
  return false;
};


const isObjectEmpty = (objectName: any) => {
  return (
    objectName &&
    Object.keys(objectName).length === 0 &&
    objectName.constructor === Object
  );
};
