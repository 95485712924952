import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AutoExpandDirective} from "./auto-expand.directive";
import {MatIconModule} from "@angular/material/icon";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {MatTooltipModule} from "@angular/material/tooltip";
import {ReadOnlyDirective} from "../read-only/read-only.directive";
import {LogService} from 'src/app/services/log/log.service';
import {TruncatePipe} from "../../../pipes/truncate/truncate.pipe";
import {Subscription} from "rxjs";
import {ChatbotComponent} from "../chatbot.component";
import {Constants} from "../../../constants/constants";
import {SharedService} from "../../../services/shared/shared.service";

@Component({
  selector: 'iv-prompt-input',
  templateUrl: './prompt-input.component.html',
  styleUrls: ['./prompt-input.component.scss'],
  imports: [
    AutoExpandDirective,
    MatIconModule,
    ReactiveFormsModule,
    MatTooltipModule,
    ReadOnlyDirective,
  ],
  standalone: true
})
export class PromptInputComponent implements OnInit, OnDestroy {
  @Input() textareaControl = new FormControl<string>('');
  @Input() allowedFeature = false;
  @Output() emitPrompt = new EventEmitter<string | null>();

  textareaSubscription: Subscription = Subscription.EMPTY;

  private truncatePipe: TruncatePipe = new TruncatePipe();

  lockInput: boolean = false;

  constructor(
    private logService: LogService,
    private chatbotComponent: ChatbotComponent,
    private sharedService: SharedService
  ) {
  }


  ngOnInit() {
    this.textareaSubscription = this.textareaControl.valueChanges.subscribe(value => {
      if(value) {
        const trimmedValue: string = value.trimStart();
        if(trimmedValue !== value) {
          setTimeout(() => {
            this.textareaControl.setValue(trimmedValue);
          });
        }
        this.chatbotComponent.setUsedTokens(trimmedValue ? trimmedValue.length : 0);
        if (!trimmedValue.length || (trimmedValue.length > Constants.chatbotMaxChars)) {
          this.lockInput = true;
        } else {
          this.lockInput = false;
        }
      } else {
        this.chatbotComponent.setUsedTokens(0);
      }
    });
  }

  simulateUserInput(text: string) {
    // Set the value of the FormControl programmatically
    this.textareaControl.setValue(text);

    // Trigger the value change logic manually if needed (such as trimming or any other logic in valueChanges)
    this.textareaControl.updateValueAndValidity();

    // Check if input is allowed and not locked, then emit the prompt
    if (this.allowedFeature && !this.lockInput && this.textareaControl.value) {
      this.logInput(text, 'precanned');
      this.emitData();
    }
  }

  logInput(query: string, state: string = 'new'): boolean {
    const isNew: boolean = this.sharedService.getChatbotConversationIsNew();
    if(state !== 'precanned') {
      state = isNew ? 'new' : 'continued';
    }
    this.logService.track('ai_prompt_submitted', false, {
      prompt_type: state,
      chat_id: this.chatbotComponent.getConversationId()
    });

    return true;
  }

  emitData() {
    if (this.textareaControl.value) {
      this.emitPrompt.emit(this.textareaControl.value);
    }
  }

  ngOnDestroy() {
    if (this.textareaSubscription !== Subscription.EMPTY) {
      this.textareaSubscription.unsubscribe();
    }
  }

}
