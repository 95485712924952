<article>
  <h1>404! Page not Found.</h1>
  <br/>
  <ng-container *ngIf="profile">
    <p>The URL: <a href="{{attemptedUrl}}">{{ attemptedUrl }}</a> you are attempting to access can not be found.</p>
    <p *ngIf="profile.email">Email: {{profile.email}}</p>
    <p>Date/time: {{dateTimeNow}} (GMT)</p>
    <br/>
  </ng-container>
  <ng-container *ngIf="!profile">
    <p>The URL: <a href="{{attemptedUrl}}">{{ attemptedUrl }}</a> you are attempting to access can not be found.</p>
    <br/>
  </ng-container>
  <p><a href="/dashboard">&#8592; Go back to the main dashboard.</a></p>
</article>
