<section>
  <div class="iv-widgets-wrapper">
    <div class="iv-widget">
      <div class="iv-widget-background-icon"></div>
      <div class="iv-widget-background-icon-wells"></div>
      <div class="iv-widget-data">
        <div class="iv-widget-title">Wells</div>
        <div class="iv-widget-desc">(Distinct API / UWI Count)</div>
        <div class="iv-widget-count">
          {{ wellCount }}
        </div>
        <div class="iv-widget-desc-date">{{ lastUpdated }}</div>

        <div
          class="iv-widget-info"
          matTooltip="US Wells spudded within 30 days. Line shows weekly count for past six months.&#10;Updated: {{
            lastUpdated
          }}"
          matTooltipPosition="below"
          matTooltipClass="iv-widget-tooltip"
        >
          <mat-icon>info</mat-icon>
        </div>
      </div>
      <datis-angular-wrapper [configuration]="configuration" />
    </div>
    <div class="iv-widget">
      <div class="iv-widget-background-icon"></div>
      <div class="iv-widget-background-icon-permits"></div>
      <div class="iv-widget-data">
        <div class="iv-widget-title">Permits</div>
        <div class="iv-widget-desc">(Distinct API / UWI Count)</div>
        <div class="iv-widget-count">
          {{ permitCount }}
        </div>
        <div class="iv-widget-desc-date">{{ lastUpdated }}</div>

        <div
          class="iv-widget-info"
          matTooltip="US Permits approved within 30 days. Line shows weekly count for past six months.&#10;Updated: {{
            lastUpdated
          }}"
          matTooltipPosition="below"
          matTooltipClass="iv-widget-tooltip"
        >
          <mat-icon>info</mat-icon>
        </div>
      </div>
      <datis-angular-wrapper [configuration]="configuration2" />
    </div>
    <div class="iv-widget">
      <div class="iv-widget-background-icon"></div>
      <div class="iv-widget-background-icon-rigs"></div>
      <div class="iv-widget-data">
        <div class="iv-widget-title">Rigs</div>
        <div class="iv-widget-desc">(Distinct API / UWI Count)</div>
        <div class="iv-widget-count">
          {{ rigCount }}
        </div>
        <div class="iv-widget-desc-date">{{ lastUpdated }}</div>

        <div
          class="iv-widget-info"
          matTooltip="US Rigs currently in operation. Line shows six month trend by week.&#10;Updated: {{
            lastUpdated
          }}"
          matTooltipPosition="below"
          matTooltipClass="iv-widget-tooltip"
        >
          <mat-icon>info</mat-icon>
        </div>
      </div>
      <datis-angular-wrapper [configuration]="configuration3" />
    </div>
  </div>
</section>
