<section class="search-results">
  <ng-container *ngIf="store && ((store.results?.results?.length ?? 0) < 1)">
    <section class="noresults">
      <h2>Your search - <strong>{{store.parameters?.input}}</strong> returned no results.</h2>
      <p>Suggestions:</p>
      <ul>
        <li>Make sure that all words are spelled correctly.</li>
        <li>Try different keywords.</li>
        <li>Try more general keywords.</li>
        <li>Try fewer keywords.</li>
      </ul>
    </section>
  </ng-container>
  <ng-container *ngIf="store && (store.results?.results?.length ?? 0) >= 1">
    <div class="locked-header">
      <section class="result-header">
        <p class="results"><strong>{{store.results!.count!}}</strong> results, page <strong>{{store.results!.page}}</strong>
          of <strong>{{ (store.results!.count / store.results!.perPage) | ceil }}</strong>
        </p>
        <ng-container *ngIf="(researchItem !== undefined) && (researchItem.chipContents !== undefined) && (researchItem.chipContents.length > 0)">
          <div *ngIf="(researchItem !== undefined) && (researchItem.chipContents !== undefined) && (researchItem.chipContents.length > 0)" class="chip-container centered-content" matTooltip="{{facetsPrettyText}}">
            <svg *ngIf="(researchItem !== undefined) && (researchItem.chipContents !== undefined) && (researchItem.chipContents.length > 0)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <circle cx="256" cy="256" r="240.845" fill="#f3f6f7" stroke="#57b947" stroke-width="23.52" paint-order="normal"/>
              <path d="M370.252 114.939H141.743c-4.602 0-7.254 5.243-4.542 8.961l90.678 123.444c.7.976 1.097 2.134 1.097 3.322v146.395h45.903V250.788c0-1.28.426-2.53 1.219-3.505l98.542-123.2c2.956-3.688.335-9.144-4.389-9.144z" fill="#58b947"/>
            </svg>
            <mat-chip-option *ngIf="(researchItem !== undefined) && (researchItem.chipContents !== undefined) && (researchItem.chipContents.length > 0)" (click)="activateChip()" class="chip-contents">
              {{chipPrettyText| shorten : 150 : "..." }}
            </mat-chip-option>
            <button (click)="removeChip()" class="close-chip">
              <mat-icon>cancel</mat-icon>
            </button>
          </div>
        </ng-container>
        <pagination-controls id="{{paginationId}}" (pageChange)="navigateToPage(store.parameters?.input, $event)" [maxSize]="0" class="top"></pagination-controls>
      </section>
      <div class="download-queue">
        <button *ngIf="downloadQueueSize()" (click)="bulkDownloadQueue()" title="{{downloadQueueTooltip}}">Download {{downloadQueueSize()}} Queued Files</button>
        <span class="download-label"><ng-container *ngIf="downloadQueueSize()">Un-check</ng-container><ng-container *ngIf="!downloadQueueSize()">Queue</ng-container> All <ng-container *ngIf="!downloadQueueSize()">for Download</ng-container></span>
        <mat-checkbox (change)="queueAll($event)" [checked]="downloadQueueSize()>0"></mat-checkbox>
        <br/>
        <mat-chip-option *ngIf="showBulkDownloadMsg" (removed)="removeEmailChip()">
          Your request is currently being processed. You will receive a link by email when your download is ready.
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-option>
      </div>
    </div>
    <ng-container
      *ngFor="let result of store.results?.results | paginate: { id: paginationId, itemsPerPage: store.results?.perPage, currentPage: store.results?.page, totalItems: store.results?.count }">
      <section class="container">
        <article [ngClass]="calcAge(result.publishedDate)">
          <div>
            <ng-container *ngIf="result.permitted">
              <div class="attachment">
                <h2><a (click)="downloadAttachment(result.parentId, result.filename)"> {{result.title ? result.title : result.filename}} ({{result.filesize | filesize}})
                  <mat-icon svgIcon="download"></mat-icon>
                </a></h2>
                <p class="parent"><a routerLink="/research/{{result.parentId}}"> {{result.parentTitle}}</a></p>
              </div>
            </ng-container>
            <ng-container *ngIf="!result.permitted">
              <h2 [matTooltip]="subscriptionRequiredMessage" matTooltipPosition="below">{{result.title}}</h2>
              <p [matTooltip]="subscriptionRequiredMessage" matTooltipPosition="below">{{result.parentTitle}}</p>
            </ng-container>
            <footer>
              <p>
                <span class="publish-date">{{result.publishedDate | amTimeAgo}}</span>
                <span><strong>{{result.series}}</strong></span>
                <span *ngIf="result.category && result.category.length > 0">{{result.category.join(", ")}}</span>
                <span *ngIf="result.subCategory && result.subCategory.length > 0">{{result.subCategory.join(", ")}}</span>
                <span *ngIf="result.countries && result.countries.length > 0">{{result.countries.join(", ")}}</span>
                <span *ngIf="result.regions && result.regions.length > 0">{{result.regions.join(", ")}}</span>
              </p>
            </footer>
          </div>
          <aside>
            <mat-checkbox *ngIf="result.permitted" [checked]="isQueued(result.id)" (change)="toggleQueued(result)"></mat-checkbox>
          </aside>
        </article>
      </section>
    </ng-container>
    <section class="result-footer">
      <p class="results"><strong>{{store.results!.count!}}</strong> results, page <strong>{{store.results!.page}}</strong>
        of <strong>{{ (store.results!.count / store.results!.perPage) | ceil }}</strong>
      </p>
      <pagination-controls id="{{paginationId}}" (pageChange)="navigateToPage(store.parameters?.input, $event)" [maxSize]="0" class="top"></pagination-controls>
    </section>
  </ng-container>
</section>
