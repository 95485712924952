import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

// import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";

@Component({
  selector: 'iv-filter-help',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule
  ],
  templateUrl: './filter-help.component.html',
  styleUrls: ['./filter-help.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterHelpComponent {

}
