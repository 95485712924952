// ------------------------------------------------------------------------------------------------
//  SavedFiltersService:
//
//  Provides methods to load, save, clone filter (facet) collections per store.
//
// ------------------------------------------------------------------------------------------------

import { EventEmitter, Injectable, Input, Output } from '@angular/core';

import {BehaviorSubject, Observable, ReplaySubject, Subscription, toArray} from "rxjs";
import { map, take } from "rxjs/operators";
import {v4 as uuidv4} from 'uuid';

import {environment as ENV} from '../../../environments/environment';
import { Constants } from "../../constants/constants";
const livefeedStores: string[] = Constants.LivefeedStores;
const reportsStores: string[] = Constants.ReportsStores;

import { AzureSearchService } from "../azuresearch/azuresearch.service";
import { DataService } from "../data/data.service";
import { LogService } from "../log/log.service";
import { SearchService } from "../search/search.service";
import { SharedService } from "../shared/shared.service";

import {ActiveFilter, createEmptyActiveFilter} from "../../interface/activefilter";
import { LoadedFilters } from "../../interface/loadedfilters";
import { Research } from "../../interface/research";
import { ResearchStore } from "../../store/research-store/research.store";

// most functions return this object on error, else null ?
interface SfError {
  errmsg: string;                       // the error message
}

@Injectable({
  providedIn: 'root'
})
export class SavedFiltersService {
  savedFiltersSub: Subscription = Subscription.EMPTY;
  clearSavedFiltersSub: Subscription = Subscription.EMPTY;

  private filtersLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public filtersLoaded$: Observable<boolean> = this.filtersLoadedSubject.asObservable();

  public researchStore: ResearchStore;

  private loadedFiltersSubject: ReplaySubject<LoadedFilters[]> = new ReplaySubject<LoadedFilters[]>(1);
  loadedFilters$: Observable<LoadedFilters[]> = this.loadedFiltersSubject.asObservable();

  filtersWereLoaded: boolean  = false;

  debug_cfn: boolean = false;                              // show function call console debug info

  constructor(private azureSearchService: AzureSearchService,
              private dataService: DataService,
              private logService: LogService,
              public searchService: SearchService,
              private _researchStore: ResearchStore,
              private sharedService: SharedService,
  ) {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::constructor()`, 'background: aqua; color: black'); }

    this.researchStore = _researchStore;

    // this is where the saved filter list is loaded
    // @ts-ignore
    this.loadFilters();

  }

  async loadFilters(): Promise<any> {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::loadFilters()`, 'background: aqua; color: black'); }
    const _self = this;

    if(!this.filtersWereLoaded) {

      let _loadedFilters: LoadedFilters[] = [];

      let userFilters = await this.dataService.getFilters();

      let globalFilters = await this.dataService.getFiltersGlobal();
      // delete any filters from global you already have (ie: you are the owner of them)
      globalFilters.result.filters = globalFilters.result.filters.filter((obj2: any) => !userFilters.result.filters.some((obj1 : any) => obj1.id === obj2.id));

      let alerts: any = {};
      if (!ENV.preview) {
        alerts = await this.dataService.getAlerts();
      }

      userFilters.result.filters.forEach(function (filter: any) {

        const settings = JSON.parse(filter.settings);
        filter.settings = settings;
        filter.prettyText = settings.prettyText;

        // if the filter has the 'isDefault' set to true (> 0) then make it the default
        if ((settings.hasOwnProperty('isDefault') && settings.isDefault)) {

          _self.researchStore.selectResearchByActiveStoreKey(settings.collection).pipe(
            take(1)
          ).subscribe((rec: any) => {
            if (rec) {
              if(rec.defaultFilterId !== filter.id) {
                rec.defaultFilterId = filter.id;
                _self.researchStore.updateResearchByActiveStoreKey({
                  activeStoreKey: settings.collection,
                  research: rec
                });
              }
            }
          });

        }

        // if the filter has an associated alert then add it to the filter object
        if (alerts.hasOwnProperty('result')) {
          alerts.result.items.forEach(function (alert: any) {
            if(alert && alert.hasOwnProperty('dataSources') &&
              alert.dataSources.hasOwnProperty('intelligencePublications') &&
              alert.dataSources.intelligencePublications.hasOwnProperty('metadata') &&
              alert.dataSources.intelligencePublications.metadata.hasOwnProperty('saved_filter_id') )
            {
              if (alert.dataSources.intelligencePublications.metadata.saved_filter_id === filter.id) {
                filter.alert = alert;
              }
            }
          });
        }

        // make sure old incompatible filters (collection == 'Research' for instance aren't added as they will break the site
        if( (reportsStores.indexOf(filter.settings.collection) >= 0) || (livefeedStores.indexOf(filter.settings.collection) >= 0) ) {
          _loadedFilters.push(filter);
        }

      });

      globalFilters.result.filters.forEach(function (filter: any) {
        const settings = JSON.parse(filter.settings);
        filter.settings = settings;
        filter.prettyText = settings.prettyText;
        // make sure old incompatible filters (collection == 'Research' for instance aren't added as they will break the site
        if( (reportsStores.indexOf(filter.settings.collection) >= 0) || (livefeedStores.indexOf(filter.settings.collection) >= 0) ) {
          _loadedFilters.push(filter);
        }
      });

      this.filtersWereLoaded = true;

      this.sortAndSetFilters(_loadedFilters);

      this.filtersLoadedSubject.next(true);

    }

  }

  public initActiveFilter(storeKey: string = ''): ActiveFilter {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::initActiveFilter(${storeKey})`, 'background: aqua; color: black'); }
    let activeFilter: ActiveFilter = createEmptyActiveFilter();
    if(storeKey !== '') {
      activeFilter.collection = storeKey;
      activeFilter.facetsDiff = this.azureSearchService.get_facetsdiff(storeKey,Constants.facetsModifiedIgnoreDates || false);
      this.azureSearchService.custom_var_set(storeKey, Constants.activeFilterKey, activeFilter);
    }
    return( activeFilter );
  }

  // clear active flag on all saved filters
  public clearFilters(storeKey: string): void {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::clearFilters(${storeKey})`, 'background: aqua; color: black'); }

    if (this.azureSearchService.test_store_exists(storeKey)) {
      this.loadedFiltersSubject.pipe(take(1)).subscribe((filters) => {
        const updatedFilters = filters.map((filter) => {
          if(storeKey === filter.settings.collection) {
            return { ...filter, active: false };
          } else {
            return filter;
          }
        });
        this.loadedFiltersSubject.next(updatedFilters);
      });
    }
  }

  public setActiveFilter(storeKey: string, id: number): void {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::setActiveFilter(${storeKey}, id ${id})`, 'background: aqua; color: black'); }
    const loadIndex$ = this.loadedFiltersSubject.pipe(take(1));
    loadIndex$.subscribe((filters: LoadedFilters[]) => {
      const relevantStores = this.getRelevantStores(storeKey);
      const loadIndex = filters.findIndex((x: LoadedFilters) => x.id === id);
      if (loadIndex >= 0) {
        const updatedFilters = filters.map((filter: LoadedFilters) => {
          if (filter.id === id) {
            return { ...filter, active: true };
          } else if (storeKey === filter.settings.collection) {
            return { ...filter, active: false };
          } else {
            return filter;
          }
        });
        this.loadedFiltersSubject.next(updatedFilters);
      }
    });
  }

  public getActiveFilterId(storeKey: string): number {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::getActiveFilterId(${storeKey})`, 'background: aqua; color: black'); }
    let id = 0;
    const loadIndex$ = this.loadedFiltersSubject.pipe(take(1));
    loadIndex$.subscribe((filters: LoadedFilters[]) => {
      filters.forEach((filter: any) => {
        if((filter.settings.collection === storeKey) && (filter.active === true)) {
          id = filter.id;
        }
      });
    });
    return id;
  }

  public getDefaultFilterId(storeKey: string): number {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::getDefaultFilterId(${storeKey})`, 'background: aqua; color: black'); }
    let retId = 0;
    this.loadedFiltersSubject.pipe(take(1)).subscribe((filters: LoadedFilters[]) => {
      for (const filter of filters) {
        if ((filter.settings.collection === storeKey) && filter.settings.hasOwnProperty('isDefault') && filter.settings.isDefault) {
          retId = filter.id;
          break;
        }
      }
    });
    return retId;
  }

  async saveFilter(storeKey: string, activeFilter: any, q: string, isDefault: boolean = false): Promise<any> {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::save_filter(${storeKey},${JSON.stringify(activeFilter)})`, 'background: aqua; color: black'); }
    const _self = this;

    this.azureSearchService.diff_facets(storeKey);
    activeFilter.collection = storeKey;
    activeFilter.facetsDiff = this.azureSearchService.get_facetsdiff(storeKey, Constants.facetsModifiedIgnoreDates || !activeFilter.dateRangeEdited);
    activeFilter.prettyText = this.azureSearchService.get_facetsdiff_pretty_text( storeKey, ' | ', '\n' );
    activeFilter.isDefault = isDefault;
    activeFilter.filterModified = false;
    activeFilter.q = q;

    let loadedFilter = this.getLoadedFilter(activeFilter.id);

    // if there is a prism alert we must update it
    let prismId: string = '';
    if(loadedFilter && loadedFilter.alert) {

      const alertId: string = loadedFilter.alert.id;

      let selectedOption = loadedFilter.alert.frequency;
      let notificationObj = this.FilterToNotification(activeFilter, selectedOption, activeFilter.facetsDiff);

      delete notificationObj.createdByApplication;
      delete notificationObj.frequency;
      delete notificationObj.globalFilters;
      delete notificationObj.push;
      delete notificationObj.type;

      await this.dataService.updateAlert(alertId, notificationObj).then((result: any) => {
        prismId = result.result.id;
        this.addOrUpdateFilterAlert(activeFilter.id, result.result);
      }, err => {
        // this.notificationService.open('An error occurred updating the email alert.', '', 5000, 'success');
        //this.logService.log(event: string, message: string, level: string, options: any);  // TODO
      });

    }

    // so where do we get id and title from?
    await this.dataService.saveFilter(activeFilter.id, activeFilter.title, activeFilter, prismId).then(result => {

      const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');

      activeFilter.id = result.result.id;
      activeFilter.titleOriginal = activeFilter.title;
      activeFilter.qOriginal = activeFilter.q;
      activeFilter.active = true;
      activeFilter.userId = profile.user_id;
      activeFilter.userEmail = profile.email;

      this.clearFilters( storeKey );
      this.azureSearchService.custom_var_set( storeKey, Constants.activeFilterKey, activeFilter );

      let saveIndex: number = 0;
      const saveIndex$ = this.loadedFiltersSubject.pipe(take(1));
      saveIndex$.subscribe((filters: LoadedFilters[]) => {
        saveIndex = filters.findIndex((x: LoadedFilters) => x.id === activeFilter.id);

        // if new filter, append it
        if (saveIndex === -1) {
          const filterData: LoadedFilters = {
            active: activeFilter.active,
            id: activeFilter.id,
            prettyText: activeFilter.prettyText,
            settings: activeFilter,
            title: activeFilter.title,
            tsModified: Date.now(),
            userId: profile.user_id
          };
          this.sortAndSetFilters([...filters, filterData]);
        } else {
          // otherwise update it
          const updatedFilters = filters.map(filter => {
            if (filter.id === activeFilter.id) {
              return {
                ...filter,
                active: activeFilter.active,
                title: activeFilter.title,
                titleOriginal: activeFilter.title,
                settings: activeFilter,
                tsModified: Date.now()
              };
            }
            return filter;
          });

          this.sortAndSetFilters(updatedFilters);
        }

      });

      if( reportsStores.indexOf(storeKey) >= 0 ) {
        this.sharedService.updateReportsActiveFilter(activeFilter);
      } else {
        if( livefeedStores.indexOf(storeKey) >= 0 ) {
          this.sharedService.updateLivefeedActiveFilter(activeFilter);
        }
      }

      this.logService.track("filter_saved", false,{
        filter_name: activeFilter.title,
        filter_selection: activeFilter.prettyText
      });

    })

    return activeFilter;
  }

  async loadFilter(storeKey: string, id: number): Promise<SfError | null> {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::loadFilter(${storeKey}, ${id})`, 'background: aqua; color: black'); }
    let sfError: SfError | null = null;

    if(id) {

      await this.dataService.loadFilter(id).then(result => {

        // recover the activeFilter
        let activeFilter: ActiveFilter = JSON.parse(result.result.filter.settings);
        activeFilter.id = result.result.filter.id;
        activeFilter.filterModified = false;
        activeFilter.titleOriginal = activeFilter.title;
        activeFilter.qOriginal = activeFilter.q;
        activeFilter.active = true;
        activeFilter.userId = result.result.filter.userId;
        activeFilter.userEmail = result.result.filter.userEmail;
        activeFilter.isGlobal = result.result.filter.isGlobal;

        // need to clear UI elements only if remaining on same tab/collection
        const q: string = activeFilter && (activeFilter.q !== undefined) ? activeFilter.q : '';
        this.searchService.setQuery(activeFilter.collection, q);
        this.searchService.setChipContents(activeFilter.collection, '');
        this.searchService.setPage(activeFilter.collection, 1);

        // set active flag on the matching filter in this.loadedFilters
        const loadIndex$ = this.loadedFiltersSubject.pipe(take(1));
        loadIndex$.subscribe((filters: LoadedFilters[]) => {
          const relevantStores = this.getRelevantStores(storeKey);
          const loadIndex = filters.findIndex((x: LoadedFilters) => x.id === id);
          if (loadIndex >= 0) {
            const updatedFilters = filters.map((filter: LoadedFilters) => {
              if (filter.id === id) {
                return {...filter, active: true};
              } else if (activeFilter.collection === filter.settings.collection) {
                return {...filter, active: false};
              } else {
                return filter;
              }
            });
            this.loadedFiltersSubject.next(updatedFilters);
          }
        });

        if (reportsStores.indexOf(storeKey) >= 0) {
          this.sharedService.updateReportsLoadingSavedFilter(true);
        } else {
          if (livefeedStores.indexOf(storeKey) >= 0) {
            this.sharedService.updateLivefeedLoadingSavedFilter(true);
          }
        }

        if (reportsStores.indexOf(storeKey) >= 0) {
          this.sharedService.updateReportsTab(activeFilter.collection);
        } else {
          if (livefeedStores.indexOf(storeKey) >= 0) {
            this.sharedService.updateLivefeedTab(activeFilter.collection);
          }
        }

        if (reportsStores.indexOf(storeKey) >= 0) {
          this.sharedService.updateReportsActiveFilter(activeFilter);
        } else {
          if (livefeedStores.indexOf(storeKey) >= 0) {
            this.sharedService.updateLivefeedActiveFilter(activeFilter);
          }
        }

        if (reportsStores.indexOf(storeKey) >= 0) {
          this.sharedService.updateReportsLoadingSavedFilter(false);
        } else {
          if (livefeedStores.indexOf(storeKey) >= 0) {
            this.sharedService.updateLivefeedLoadingSavedFilter(false);
          }
        }


        this.researchStore.selectResearchByActiveStoreKey(activeFilter.collection).pipe(
          take(1)
        ).subscribe(rec => {
          if (rec) {
            if (activeFilter.q) {
              rec.activeFilterId = activeFilter.id;
              rec.q = activeFilter.q;
              rec.chipContents = activeFilter.q;
            }
            this.researchStore.updateResearchByActiveStoreKey({
              activeStoreKey: activeFilter.collection,
              research: rec
            });
          }
        });


        // set the facets to the loaded filter
        this.azureSearchService.clear_all_facets(activeFilter.collection, false);
        this.azureSearchService.set_facets_diff(activeFilter.collection, activeFilter.facetsDiff);
        this.searchService.ss_set_subscription(activeFilter.collection, '', false);

        this.azureSearchService.facetsDiff_to_facets(activeFilter.collection, true);
        this.searchService.ss_set_collection(activeFilter.collection, true);

        if (reportsStores.indexOf(storeKey) >= 0) {
          this.searchService.clearQueryParams(Constants.reportsSavedFiltersUrl);
          this.searchService.removeQueryParam(Constants.reportsSavedFiltersUrl);
        } else {
          if (livefeedStores.indexOf(storeKey) >= 0) {
            this.searchService.clearQueryParams(Constants.livefeedSavedFiltersUrl);
            this.searchService.removeQueryParam(Constants.livefeedSavedFiltersUrl);
          }
        }

      });

    }

    return sfError;
  }

  private getRelevantStores(storeKey: string): string[] {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::getRelevantStores(${storeKey})`, 'background: aqua; color: black'); }
    // Choose the relevant stores based on the storeKey
    if (Constants.ReportsStores.includes(storeKey)) {
      return Constants.ReportsStores;
    } else if (Constants.LivefeedStores.includes(storeKey)) {
      return Constants.LivefeedStores;
    } else {
      // Default to an empty array if the storeKey is not found in any of the stores
      return [];
    }
  }

  async deleteFilter(storeKey: string, id: number): Promise<SfError | null> {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::deleteFilter(${storeKey}, ${id})`, 'background: aqua; color: black'); }
    let sfError: SfError | null = null;
    const _self = this;

    const filterToDelete = this.getLoadedFilter(id);
    if(filterToDelete && filterToDelete.hasOwnProperty('alert')) {
      await this.dataService.deleteAlert(filterToDelete.alert.id);
    }

    await this.dataService.deleteFilter(id).then(result => {

      const delIndex$ = this.loadedFiltersSubject.pipe(take(1));
      delIndex$.subscribe((filters: LoadedFilters[]) => {
        const delIndex = filters.findIndex((x: LoadedFilters) => x.id === id);
        if (delIndex >= 0) {
          const updatedFilters = filters.filter((filter: LoadedFilters, i: number) => i !== delIndex);
          this.loadedFiltersSubject.next(updatedFilters);
        }
      });

    })

    return sfError;
  }

  // delete alert sub-object from loaded filter in memory
  async deleteFilterAlert(id: number): Promise<SfError | null> {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::deleteFilterAlert(${id})`, 'background: aqua; color: black'); }
    let sfError: SfError | null = null;
    const _self = this;

    const delIndex$ = this.loadedFiltersSubject.pipe(take(1));
    delIndex$.subscribe((filters: LoadedFilters[]) => {
      const delIndex = filters.findIndex((x: LoadedFilters) => x.id === id);
      if (delIndex >= 0) {
        const updatedFilter = {...filters[delIndex]};
        delete updatedFilter.alert;
        const updatedFilters = [...filters];
        updatedFilters[delIndex] = updatedFilter;
        this.loadedFiltersSubject.next(updatedFilters);
      }
    });
    return sfError;
  }

  // add alert sub-object to loaded filter in memory
  async addOrUpdateFilterAlert(id: number, alert: any): Promise<SfError | null> {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::addOrUpdateFilterAlert(${id})`, 'background: aqua; color: black'); }
    let sfError: SfError | null = null;
    const _self = this;

    const addIndex$ = this.loadedFiltersSubject.pipe(take(1));
    addIndex$.subscribe((filters: LoadedFilters[]) => {
      const addIndex = filters.findIndex((x: LoadedFilters) => x.id === id);
      if (addIndex >= 0) {
        const updatedFilter = {...filters[addIndex]};
        updatedFilter.alert = alert;
        const updatedFilters = [...filters];
        updatedFilters[addIndex] = updatedFilter;
        this.loadedFiltersSubject.next(updatedFilters);
      }
    });
    return sfError;
  }

  // get alert sub-object from a loaded filter in memory
  async getFilterAlert(id: number): Promise<any | null> {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::getFilterAlert(${id})`, 'background: aqua; color: black'); }

    let retAlert: any | null = null;
    const _self = this;
    const getIndex$ = this.loadedFiltersSubject.pipe(take(1));
    getIndex$.subscribe((filters: LoadedFilters[]) => {
      const getIndex = filters.findIndex((x: LoadedFilters) => x.id === id);
      if (getIndex >= 0) {
        retAlert = JSON.parse( JSON.stringify( filters[getIndex].alert ) );
      }
    });
    return retAlert;
  }

  async publishFilter(id: number, isGlobal: boolean): Promise<SfError | null> {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::publishFilter(id ${id}, isGlobal ${isGlobal})`, 'background: aqua; color: black'); }
    let sfError: SfError | null = null;

    await this.dataService.publishFilter(id, isGlobal).then(result => {

      let saveIndex: number = 0;
      const saveIndex$ = this.loadedFiltersSubject.pipe(take(1));
      saveIndex$.subscribe((filters: LoadedFilters[]) => {
        saveIndex = filters.findIndex((x: LoadedFilters) => x.id === id);

        if (saveIndex >= 0) {
          const updatedFilters = filters.map(filter => {
            if (filter.id === id) {
              return {
                ...filter,
                isGlobal: isGlobal,
                tsModified: Date.now()
              };
            }
            return filter;
          });
          this.sortAndSetFilters(updatedFilters);
        }

      });

    })

    return sfError;
  }

  public getLoadedFilter(id: number): LoadedFilters | null {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::getLoadedFilter(${id})`, 'background: aqua; color: black'); }
    let filter: LoadedFilters | null = null;
    this.loadedFiltersSubject.pipe(take(1)).subscribe((filters: LoadedFilters[]) => {
      filter = filters.find((x: LoadedFilters) => x.id === id) || null;
    });
    return filter;
  }

  public getLoadedFilterByTitle(title: string): LoadedFilters | null {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::getLoadedFilterByTitle(${title})`, 'background: aqua; color: black'); }
    let filter: LoadedFilters | null = null;
    this.loadedFiltersSubject.pipe(take(1)).subscribe((filters: LoadedFilters[]) => {
      filter = filters.find((x: LoadedFilters) => x.title === title) || null;
    });
    return filter;
  }

  public updateFilterActive(storeKey: string, id: number, isDefault: boolean): void {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::updateFilterActive(${storeKey}, ${id}, ${isDefault})`, 'background: aqua; color: black'); }

    this.loadedFiltersSubject.pipe(take(1)).subscribe((filters: LoadedFilters[]) => {

      const updatedFilters = filters.map((filter) => {
        if (filter.id === id) {
          if (filter.settings.isDefault !== isDefault) {
            filter.settings.isDefault = isDefault;
            this.dataService.updateFilterSettings(id, {'isDefault': isDefault});
          }
          return {
            ...filter,
            settings: filter.settings
          };
        } else if (storeKey === filter.settings.collection) {
          if (filter.settings.isDefault !== false) {
            filter.settings.isDefault = false;
            this.dataService.updateFilterSettings(filter.id, {'isDefault': false});
          }
          return {
            ...filter,
            settings: filter.settings
          };
        }
        return filter;
      });

      this.loadedFiltersSubject.next(updatedFilters);
    });
  }

  public getDefaultFilterSettings(): any {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::getDefaultFilterSettings()`, 'background: aqua; color: black'); }
    let retSettings = null;
    this.loadedFiltersSubject.pipe(take(1)).subscribe((filters: LoadedFilters[]) => {
      for (const filter of filters) {
        if (filter.settings.hasOwnProperty('isDefault') && filter.settings.isDefault) {
          retSettings = filter.settings;
          break;
        }
      }
    });
    return retSettings;
  }

  public getDefaultFilterLoaded(storeKey: string): boolean {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::getDefaultFilterLoaded(${storeKey})`, 'background: aqua; color: black'); }
    let filterLoaded: boolean = false;
    this.researchStore.selectResearchByActiveStoreKey(storeKey).pipe(
      take(1)
    ).subscribe(rec => {
      if (rec) {
        filterLoaded = rec.defaultFilterLoaded;
      }
    });
    return filterLoaded;
  }

  public setDefaultFilterLoaded(storeKey: string): void {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::setDefaultFilterLoaded(${storeKey})`, 'background: aqua; color: black'); }
    this.researchStore.selectResearchByActiveStoreKey(storeKey).pipe(
      take(1)
    ).subscribe(rec => {
      if (rec) {
        if(!rec.defaultFilterLoaded) {
          rec.defaultFilterLoaded = true;
          this.researchStore.updateResearchByActiveStoreKey({
            activeStoreKey: storeKey,
            research: rec
          });
        }
      }
    });
  }

  private sortAndSetFilters(filters: any): void {
    filters.sort(function (a: any, b: any) {
      const indexAReports: number = reportsStores.indexOf(a.settings.collection);
      const indexBReports: number = reportsStores.indexOf(b.settings.collection);
      const indexALivefeed: number = livefeedStores.indexOf(a.settings.collection);
      const indexBLivefeed: number = livefeedStores.indexOf(b.settings.collection);

      // Compare collections based on custom order
      if (indexAReports !== -1 && indexBReports !== -1) {
        // Both 'a' and 'b' are in ReportsStores
        if (indexAReports < indexBReports) {
          return -1;
        } else if (indexAReports > indexBReports) {
          return 1;
        } else {
          // Collections are the same, compare titles alphabetically
          if (a.title.toLowerCase() < b.title.toLowerCase()) {
            return -1;
          } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        }
      } else if (indexALivefeed !== -1 && indexBLivefeed !== -1) {
        // Both 'a' and 'b' are in LivefeedStores
        if (indexALivefeed < indexBLivefeed) {
          return -1;
        } else if (indexALivefeed > indexBLivefeed) {
          return 1;
        } else {
          // Collections are the same, compare titles alphabetically
          if (a.title.toLowerCase() < b.title.toLowerCase()) {
            return -1;
          } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        }
      } else if (indexAReports !== -1 && indexBLivefeed !== -1) {
        // 'a' is in ReportsStores, 'b' is in LivefeedStores
        return -1; // ReportsStores come before LivefeedStores
      } else if (indexALivefeed !== -1 && indexBReports !== -1) {
        // 'a' is in LivefeedStores, 'b' is in ReportsStores
        return 1; // LivefeedStores come after ReportsStores
      }

      // If not found in either array, compare collections alphabetically
      if (a.settings.collection < b.settings.collection) {
        return -1;
      } else if (a.settings.collection > b.settings.collection) {
        return 1;
      } else {
        // Collections are the same, compare titles alphabetically
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      }
    });

    this.loadedFiltersSubject.next(filters);
  }

  extractAclStrings(inputString: string, outputArray: string[]): void {
    const regex = /acl\/any\(t: t eq '([^']+?)'\)/g;
    let match;
    while ((match = regex.exec(inputString)) !== null) {
      outputArray.push(match[1]);
    }
  }

  uniqueAndSortArray(inputArray: string[]): string[] {
    const uniqueArray: string[] = Array.from(new Set(inputArray));
    uniqueArray.sort();
    return uniqueArray;
  }

  public FilterToNotification(filter: any, freq: string, facetsDiff: any): any {
    const _self = this;
    let retObj: any = null;

    if(filter && facetsDiff && (['d','w','m'].indexOf(freq.charAt(0)) >= 0) ) {

      // primary alert definition
      retObj =
        {
          "name": filter.title,
          "type": "UserAlertDefinition",
          "createdByApplication": "intelligenceVault",
          "frequency": freq.charAt(0),
          "push": false,
          "globalFilters": [],
          "dataSources": {
            "intelligencePublications": {
              "metadata": {
                filter_deeplink: filter.url,
                saved_filter_id: filter.id
              },
              "filters": [],
              "trackingColumns": [
                "publishedDate"
              ]
            }
          }
        };

      // add in acls
      let aclList: string[] = [];
      facetsDiff.subscriptionFilters.filters.forEach(function (acl: any) {
        if(acl.active) {
          _self.extractAclStrings(acl.filter, aclList);
        }
      });
      if(aclList.length) {
        const uniqueArray: string[] = this.uniqueAndSortArray(aclList);
        let filterObj: any =
          {
            "type": "List",
            "value": uniqueArray,
            "logical": "any",
            "exclude": false,
            "definition": {
              "type": "column",
              "column": "acl"
            }
          };
        retObj.dataSources.intelligencePublications.filters.push( filterObj );
      }

      let collection: string = '';
      if(filter.hasOwnProperty('settings')) {
        collection = filter.settings.collection;
      } else {
        collection = filter.collection;
      }

      // add 'collection' filter
      if (reportsStores.indexOf(collection) >= 0) {

        let filterObj: any =
          {
            "type": "List",
            "value":  [collection === 'Reports' ? 'Intelligence' : 'Operator Profiles'],
            "logical": "all",
            "exclude": false,
            "definition": {
              "type": "column",
              "column": "collection"
            }
          };
        retObj.dataSources.intelligencePublications.filters.push( filterObj );

      } else {

        if (livefeedStores.indexOf(collection) >= 0) {
          let filterObj: any =
            {
              "type": "List",
              "value":  ["Live Feed"],
              "logical": "any",
              "exclude": false,
              "definition": {
                "type": "column",
                "column": "collection"
              }
            };
          retObj.dataSources.intelligencePublications.filters.push( filterObj );
        }

      }

      // add in facets
      facetsDiff.facets.forEach(function (facet: any) {

        let filterObj: any =
          {
            "type": "List",
            "value": [],
            "logical": facet.facetsCombineUsingAnd ? "all" : "any",
            "exclude": false,
            "definition": {
              "type": "column",
              "column": facet.key
            }
          };

        if(facet.dataType === 'string') {
          delete filterObj.logical;
        }

        facet.values.forEach(function (valueRec: any) {
          if(valueRec.selected === true) {
            filterObj.value.push( valueRec.value );
          }
        });

        retObj.dataSources.intelligencePublications.filters.push( filterObj );

      });

    }

    return retObj;
  }

}
