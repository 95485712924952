import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {environment as ENV} from '../../../environments/environment';
import merge from 'deepmerge';
import {RudderAnalytics} from '@rudderstack/analytics-js';
import {isPlatformServer} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  profile: null | any = null;
  user: null | any = null;
  analytics: RudderAnalytics | undefined;
  public article: any | null = null;
  private analyticsReadyPromise!: Promise<void>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
    if (!ENV.review) {
      if (!isPlatformServer(this.platformId)) {
        if (window && window.rudderanalytics as RudderAnalytics) {
          return;
        }
        this.analyticsReadyPromise = new Promise<void>((resolve) => {
          this.analytics = new RudderAnalytics();

          this.analytics.load(ENV.RUDDERSTACK.KEY, ENV.RUDDERSTACK.URL, {
            configUrl: `${ENV.RUDDERSTACK.API}`,
            pluginsSDKBaseURL: `${ENV.RUDDERSTACK.CDN}/v3/modern/plugins`,
            destSDKBaseURL: `${ENV.RUDDERSTACK.CDN}/v3/modern/js-integrations`,
            setCookieDomain: ENV.baseDomain,
            sameDomainCookiesOnly: true
          });

          this.analytics.ready(() => {
            console.info('RS Initialized');
            resolve();
          });
        });
      }
    }
  }

  public setProfileAndUser(profile: any, user: any) {
    if (!isPlatformServer(this.platformId)) {
      (window as any).userInfo = {};
      (window as any).userInfo.email = user.email;
      if (!ENV.review) {
        this.analyticsReadyPromise.then(() => {
          if (!this.profile) {
            this.profile = profile;
            this.analytics?.group(profile.app_metadata.organization.id, {
              organization_name: profile.app_metadata.organization.name,
              organization_id: profile.app_metadata.organization.id,
              organization_enverus_id: profile.app_metadata.organization.enverusId,
              organization_salesforceId: profile.app_metadata.organization.sfId
            });
          }
          if (!this.user) {
            this.user = user;

            this.analytics?.identify(user.userId, {
              user_id_type: 'enverus_user_id',
              user_auth0_id: user.azId,
              user_enverus_id: user.userId,
              user_email: user.email,
              user_first_name: user.givenName,
              user_last_name: user.familyName,
              user_subscription: 'Intelligence',
              user_roles: user.roles,
              user_permissions: user.permissions,
            });
          }
        });
      }
    }
  }

  public track(event: string, mergeArticleDetails: boolean = true, addProperties: any = null, delProperties: string[] = []) {
    if (!(ENV.review)) {
      let trackProperties = {
        application_name: 'Intelligence Vault',
        application_version: '2.0'
      };
      if (addProperties) {
        trackProperties = merge(trackProperties, addProperties);
      }
      if (mergeArticleDetails && this.article) {
        trackProperties = merge(trackProperties, this.mapArticle());
      }
      delProperties.forEach((property: string) => {
        if (trackProperties.hasOwnProperty(property)) {
          // @ts-ignore
          delete trackProperties[property];
        }
      });
      this.analytics?.track(event, trackProperties as any);
    }
  }

  public page(url: string) {
    if (!ENV.review) {
      this.analytics?.page(url, url, {
        application_name: 'Intelligence Vault',
        application_version: '2.0'
      });
    }
  }

  private mapArticle() {
    const supportingAuthors = [];
    if (this.article.supportingAuthors) {
      for (const author of this.article.supportingAuthors) {
        supportingAuthors.push(author.displayName);
      }
    }
    const retArticle: any =
      {
        article_id: this.article.id,
        article_key: this.article.key,
        article_title: this.article.title,
        article_series: this.article.series,
        article_type: this.article.intelligenceType,
        article_collection: this.article.collection,
        article_primary_author: this.article.primaryAuthor?.displayName,
        article_supporting_authors: supportingAuthors,
        article_published_date: new Date(this.article.publishedDate).getTime() / 1000,
      };
    return retArticle;
  }
}
