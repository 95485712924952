
// most functions return this object on error, else null
import {Research} from "./research";
import {ActiveFilter} from "./activefilter";
import { environment as ENV } from '../../environments/environment';

export interface AzssError {
  errmsg: string;                                         // the error message
}

// initialize the store
export interface AzssStore {
  collection?: string;                                    // --- COLLECTION: store name / key (internal)
  config?: {                                              // --- CONFIG: store configuration options
    clientKey: string;                                    // init_client() key
    clear_facets_processor: {};                           // keyed list of callback()s to run at end of clear_all_facets()
  },
  custom?: {},                                            // --- CUSTOM: add user-defined custom variables to each store
  facetCount?: any,                                       // --- FACETCOUNT: tracks result totals by facet and overall
  facets?: {                                              // --- FACETS: track state of facets
    // facetKeys: [],                                     // keys in facets (in same order); SHOULD BE AN OBJECT (ASSOCIATIVE "ARRAY") FOR FAST SEARCH?
    facetMode: string;                                    // facet search mode
    facets: any;                                          // facets
    globalFilters: {};                                    // global search filters
  },
  facetsDiff?: {                                          // --- FACETSDIFF: keeps list of selected facets
    facets: [];                                           // facets
    subscriptionFilters: {};                              // saves state of root level subscriptionFilters
  },
  facetsMaster?: {                                        // --- FACETSMASTER: used for Show All Filters
    facets: [];                                           // facets (pre-filtered) for current search parameters
    globalFilters: {};                                    // global search filters
  },
  parameters?: {                                          // --- PARAMETERS: search / suggestion parameters
    input: string;
    searchParameters: {
      count: boolean;
      includeTotalCount: boolean;
      searchFields: string[];
      orderBy: string;
      skip: number;
      top: number;
      searchMode: string;
      queryType: string;
      additionalFilters?: string;                         // additional filters to apply, ie: '(publishedDate ge 2023-06-21T00:00:00.374Z)'
      scoringProfile?: string,
      scoringStatistics? : string,
      featuresMode? : string,
      speller?: string,
      queryLanguage?: string,
      // select: null,
      // highlight: null,
      // highlightPreTag: null,
      // highlightPostTag: null,
      // scoringParameters: null
    },
    suggestParameters?: {
      orderBy: string;
      searchFields: [];
      select: string[];
      top: number;
      filter: string;
      fuzzy: boolean;
      highlightPostTag: string;
      highlightPreTag: string;
      suggesterName: string;
    }
  },
  results?: {                                             // --- RESULTS: results returned from azure search (post-processed)
    count: number;
    isFetching: boolean;
    lastUpdated: number;
    page: number;                                         // the page returned
    perPage: number;                                      // results per page
    noResults: boolean;                                   // indicates search parameters returned 0 results (azss store NOT overwritten)
    firstResultsFetched: boolean;                         // false until we received at least one set of results
    //processor: callback(),                              // optional results processor callback via search_results_processor()
    results: any;
  },
  subscriptionFilters?: {                                 // --- SUBSCRIPTION FILTERS: filters and state per subscription (Intelligence Type)
    count: number;                                        // count of filters[] set to active
    filters: [                                            // one record per "Intelligence Type" facet
      // {
      //   key: string,                                   // the key to reference this record
      //   name: string,                                  // the ui displayable name
      //   active: boolean,                               // whether the facet record is active (in searches, ui)
      //   filter: string                                 // the search filter to apply
      // },
    ];
  },
  suggestions?: {                                         // --- SUGGESTIONS: results returned from azure suggestions
    isFetching: boolean;
    lastUpdated: number;
    //processor: callback(),                              // optional suggestions processor callback via suggestions_results_processor()
    suggestions: [];
  }
}

export interface AzssBank {
  [key: string]:                AzssStore;
}

export function createEmptyAzssStore(clientKey: string, storeKey: string): AzssStore {
  return {
    collection: storeKey,
    config: {clientKey: clientKey && clientKey.length ? clientKey : '', clear_facets_processor: {}},
    custom: {},
    facetCount: {},
    facets: {facetMode: 'simple', facets: [], globalFilters: {}},
    facetsDiff: {facets: [], subscriptionFilters: {}},
    facetsMaster: {facets: [], globalFilters: {}},
    parameters: {
      input: '',
      searchParameters: {
        count: true, includeTotalCount: true,
        searchFields: ['title', 'descriptionText', 'content', 'companies', 'stockTickers',
          'regions', 'basins', 'plays', 'intervals', 'keywords', 'series', 'countries', 'subCategory' ],
        orderBy: '', skip: 0, top: 20, searchMode: 'any', queryType: 'simple',
        scoringProfile: ENV.SEARCH.scoringProfile,
        featuresMode : "enabled",
        scoringStatistics : "global",
        speller: "lexicon",
        queryLanguage: "en-us",
      },
      suggestParameters: {
        orderBy: 'publishedDate desc',
        searchFields: [],
        select: ['title', 'authors', 'descriptionText', 'acl', 'companies'],
        top: 5,
        filter: '',
        fuzzy: false,
        highlightPostTag: '</strong>',
        highlightPreTag: '<strong>',
        suggesterName: 'sg'
      }
    },
    results: {
      count: -1,
      isFetching: false,
      lastUpdated: 0,
      page: 0,
      perPage: 20,
      noResults: false,
      firstResultsFetched: false,
      results: []
    },
    subscriptionFilters: {count: 0, filters: []},
    suggestions: {isFetching: false, lastUpdated: 0, suggestions: []}
  };
}
