import { WidgetsService } from './../../services/widgets/widgets.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { DatisSchema } from '@enverus/datis-schema';
import { DatisComponent } from './datis-wrapper.component';
import { TechUserAuthService } from './widgets-data.service';

@Component({
  selector: 'iv-widgets-area',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    DatisComponent,
  ],
  templateUrl: './widgets-area.component.html',
  styleUrls: ['./widgets-area.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WidgetsAreaComponent implements OnInit {
  wellCount = 'Loading...';
  permitCount = 'Loading...';
  rigCount = 'Loading...';
  lastUpdated = 'Loading...';
  constructor(
    private widgetsService: WidgetsService,
    private techUserAuthService: TechUserAuthService
  ) {
    const todayDate = new Date();

    const weekAgoFormatted = todayDate.toLocaleDateString('en-US', {
      day: '2-digit',
      month: 'short',
    });

    this.lastUpdated = weekAgoFormatted;
  }

  sortGraphData(value: any) {
    return value.data
      .sort(
        (a: any, b: any) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      )
      .map((data: any) => ({
        date: new Date(data.date),
        count: data.count,
      }));
  }

  async getData() {
    await this.techUserAuthService.getToken();
    this.widgetsService.getWidgetWellsGraphData().then((result) =>
      result.subscribe((value) => {
        const sortedData = this.sortGraphData(value);

        this.configuration = {
          ...this.configuration,
          sources: { graph: { data: sortedData } },
        };
      })
    );
    this.widgetsService.getWidgetPermitsGraphData().then((result) =>
      result.subscribe((value) => {
        const sortedData = this.sortGraphData(value);
        this.configuration2 = {
          ...this.configuration2,
          sources: { graph: { data: sortedData } },
        };
      })
    );

    this.widgetsService.getWidgetRigsGraphData().then((result) =>
      result.subscribe((value) => {
        const sortedData = this.sortGraphData(value);
        this.configuration3 = {
          ...this.configuration3,
          sources: { graph: { data: sortedData } },
        };
      })
    );
    this.widgetsService.getWidgetWellData().then((result) => {
      result.subscribe((value) => {
        if (value.data) {
          this.wellCount = value.data[0].count.toLocaleString('en-US');
        } else {
          this.wellCount = 'Invalid Data';
        }
      });
    });
    this.widgetsService.getWidgetPermitsData().then((result) => {
      result.subscribe((value) => {
        if (value.data) {
          this.permitCount = value.data[0].count.toLocaleString('en-US');
        } else {
          this.permitCount = 'Invalid Data';
        }
      });
    });
    this.widgetsService.getWidgetRigsData().then((result) => {
      result.subscribe((value) => {
        if (value.data) {
          this.rigCount = value.data[0].count.toLocaleString('en-US');
        } else {
          this.rigCount = 'Invalid Data';
        }
      });
    });
  }
  ngOnInit(): void {
    this.getData();
  }

  configuration: DatisSchema = {
    schemaVersion: 1,
    marks: [
      {
        type: 'area',
        dataKey: 'graph',
        x: {
          field: 'date',
        },
        y: {
          field: 'count',
        },

        color: {
          scale: {
            range: ['#3f8a23'],
          },
        },
      },
    ],
    axes: [
      {
        orientation: 'bottom',
        dataKey: 'graph',
        field: 'date',
        ticks: {
          count: 4,
        },
      },
      {
        orientation: 'left',
        dataKey: 'graph',
        field: 'count',
        ticks: {
          count: 3,
        },
      },
    ],
    layout: {
      margin: {
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      },
    },
    sources: {
      graph: {
        transforms: [
          {
            type: 'date',
            field: {
              field: 'date',
            },
          },
        ],
        data: [],
      },
    },
  };
  configuration2: DatisSchema = { ...this.configuration };
  configuration3: DatisSchema = { ...this.configuration };
}
