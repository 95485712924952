import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MomentModule } from 'ngx-moment';
import { SnackbarArticle } from "../../interface/snackbararticles";
import {RouterLink} from "@angular/router";
import {MatIconModule} from "@angular/material/icon";
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import {SnackbarArticlesService} from "../../services/snackbararticles/snackbararticles.service";
import {NgStringPipesModule} from "ngx-pipes";
import { Constants } from "../../constants/constants";
import {LogService} from '../../services/log/log.service';

@Component({
  selector: 'iv-snackbar-article',
  standalone: true,
  imports: [
    CommonModule,
    MomentModule,
    RouterLink,
    MatIconModule,
    NgStringPipesModule
  ],
  templateUrl: './snackbar-article.component.html',
  styleUrls: ['./snackbar-article.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SnackbarArticleComponent implements OnInit, OnDestroy {

  titleLength: number = Constants.snackbarTitleLength;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarArticle,
              private snackBarRef: MatSnackBarRef<SnackbarArticleComponent>,
              private snackbarArticleService: SnackbarArticlesService,
              private router: Router,
              private logService:LogService
  ) {}

  ngOnInit() {
  }

  navigateToArticle(event: Event, article: SnackbarArticle): void {
    event.preventDefault();
    this.snackbarArticleService.dismissArticle(article.id);
    this.snackBarRef.dismiss();
    window.open(`/research/${article.id}`, '_blank');

    this.logService.track("snackbar_article_opened", false,{
      article_id: article.id,
      article_key: article.key,
      article_title: article.title,
      article_series: article.series,
      article_collection: article.collection,
      article_authors: article.authors,
      article_published_date: new Date(article.publishedDate).getTime() / 1000
    });

  }

  dismissAll(): void {
    this.snackbarArticleService.expireAllArticles();
    this.snackBarRef.dismiss();
  }

  formatTimeDifference(unixTime: number): string {
    const now = new Date().getTime() / 1000; // Current time in Unix timestamp
    const diffInSeconds = now - (unixTime / 1000);

    if (diffInSeconds < 60) {
      return `${Math.floor(diffInSeconds)}s ago`;
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes}m ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      const minutes = Math.floor((diffInSeconds % 3600) / 60);
      return `${hours}h ${minutes}m ago`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      const hours = Math.floor((diffInSeconds % 86400) / 3600);
      const minutes = Math.floor((diffInSeconds % 3600) / 60);
      return `${days}d ${hours}h ${minutes}m ago`;
    }
  }

  ngOnDestroy() {
  }

}
