<ul>
  <ng-container *ngIf="azureSearchService.get_subscription_filters_filters(store.collection) as subscriptions">
    <ng-container *ngIf="subscriptions">
      <ng-container *ngFor="let subscription of subscriptions; trackBy: getSubscriptionKey">
        <li *ngIf="subscriptionAllowed(subscription.key) && testPermissions(subscription.filter)" [ngClass]="subscription.active ? 'active' : ''" (click)="filterSubscription($event, subscription.key)">
          {{subscription.name}}
        </li>
      </ng-container>
    </ng-container>
  </ng-container>
</ul>
