import {enableProdMode} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {AppClientModule} from "./app/app.client.module";

if (environment.production) {
  console.log('--- PRODUCTION MODE ---')
  enableProdMode();
}

function bootstrap() {
  platformBrowserDynamic()
    //.bootstrapModule(AppModule)
    .bootstrapModule(AppClientModule)
    .catch(err => console.error(err));
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
