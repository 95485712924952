import { Pipe, PipeTransform } from '@angular/core';
import { filesize } from "filesize";

@Pipe({
  name: 'filesize',
  standalone: true
})
export class FileSizePipe implements PipeTransform {
  private static transformOne(value: number, options?: any): any {
    return filesize(value, options);
  }

  transform(value: number | number[], options?: any): any {
    let retValue: string = '';

    if(typeof(value) !== 'undefined') {
      if (Array.isArray(value)) {
        return value.map(val => FileSizePipe.transformOne(val, options));
      }
      retValue = FileSizePipe.transformOne(value, options);
    }

    return retValue;
  }
}
