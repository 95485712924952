import {AfterViewInit, Directive, ElementRef, Input, OnDestroy} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Subject, takeUntil} from "rxjs";

@Directive({
  selector: '[ivAutoExpand]',
  standalone: true
})
export class AutoExpandDirective implements AfterViewInit, OnDestroy {
  @Input() formControl: FormControl = new FormControl<string>('');
  private unsubscribe$ = new Subject();
  constructor(private el: ElementRef) { }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    this.adjustTextareaHeight(this.el.nativeElement);
    this.formControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.adjustTextareaHeight(this.el.nativeElement));
  }

  private adjustTextareaHeight(textarea: HTMLTextAreaElement): void {
    textarea.style.overflow = 'hidden';
    textarea.style.height = 'auto';
    textarea.style.height = (textarea.scrollHeight) + 'px';
  }

}
