<ng-container *ngIf="(researchItem !== undefined) && (researchItem.chipContents !== undefined) && (researchItem.chipContents.length > 0)">
  <div class="result-header">
    <div *ngIf="(researchItem !== undefined) && (researchItem.chipContents !== undefined) && (researchItem.chipContents.length > 0)" class="chip-container centered-content" matTooltip="{{facetsPrettyText}}">
      <svg *ngIf="(researchItem !== undefined) && (researchItem.chipContents !== undefined) && (researchItem.chipContents.length > 0)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <circle cx="256" cy="256" r="240.845" fill="#f3f6f7" stroke="#57b947" stroke-width="23.52" paint-order="normal"/>
        <path d="M370.252 114.939H141.743c-4.602 0-7.254 5.243-4.542 8.961l90.678 123.444c.7.976 1.097 2.134 1.097 3.322v146.395h45.903V250.788c0-1.28.426-2.53 1.219-3.505l98.542-123.2c2.956-3.688.335-9.144-4.389-9.144z" fill="#58b947"/>
      </svg>
      <mat-chip-option *ngIf="(researchItem !== undefined) && (researchItem.chipContents !== undefined) && (researchItem.chipContents.length > 0)" [disableRipple]=true (click)="activateChip()" class="chip-contents">
        {{chipPrettyText| shorten : 150 : "..." }}
      </mat-chip-option>
      <button (click)="removeChip()" class="close-chip">
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </div>
</ng-container>
