import {ActivatedRouteSnapshot, ResolveFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {DataService} from '../../services/data/data.service';
import {catchError, of} from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import {map} from "rxjs/operators";

export const researchDetailResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot
) => {
  const id = route.paramMap.get('docId');
  const router = inject(Router);
  const attemptedUrl: string = route.url.map(segment => segment.path).join('/'); // Capture the attempted URL
  const transactionId: string = uuidv4();

  return inject(DataService).getResearchMetaData(id, transactionId).pipe(
    map(metadata => ({
      metadata,
      transactionId
    })),
    catchError((error) => {
      let errorPage = '/error';
      switch (error.status) {
        case 404:
          errorPage = '/404';
          break;
        case 401:
        case 403:
          errorPage = '/403';
          break;
        default:
          break;
      }
      router.navigate([errorPage], {
        queryParams: { attemptedUrl: `/${attemptedUrl}` }, // Pass the original URL
        skipLocationChange: true
      });
      return of(error);
    })
  );
};
