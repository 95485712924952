<!-- WARNING: If changing this disclosure, you must update the date field in: constants/constants.ts :: chatbotDisclosureDate -->
<div class="disclosure">
  <div class="dialog-title">
    <span>AI System Disclosure</span>
    <button (click)="closeDisclosureDialog()">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
  <div class="content">
    <p><strong>Introduction:</strong> Thank you for using our AI-powered system! We believe in transparency and want to provide you with important information about how our AI works. Please read this disclosure carefully.</p>
    <ol>
      <li><strong>Purpose of the AI System:</strong></li>
      <ul>
        <li>Our AI system is designed to assist with natural language queries of research content published by Enverus Intelligence teams.</li>
        <li>It analyzes data and provides responses based on patterns and algorithms.</li>
      </ul>
      <li><strong>Training Data:</strong></li>
      <ul>
        <li>Our AI system was trained on a diverse dataset collected from various sources.</li>
        <li>The training data includes content published by Enverus Intelligence Research. Any user’s experience of the model will be dependent on their access level to the Intelligence product suite.</li>
        <li>While we strive for accuracy, biases present in the training data may impact results.</li>
      </ul>
      <li><strong>Algorithm and Model:</strong></li>
      <ul>
        <li>We use Anthropic Model (via AWS Bedrock) for our AI system.</li>
        <li>The model architecture involves the language model, the intelligence vault dataset and the extraction and data management processes attached with it.</li>
        <li>Our team continuously updates and refines the architecture to ensure quality and performance.</li>
      </ul>
      <li><strong>Limitations:</strong></li>
      <ul>
        <li>Our AI system has limitations:
          <ul>
            <li>It may not understand context perfectly.</li>
            <li>It does not have emotions or personal experiences.</li>
            <li>It may occasionally make errors.</li>
          </ul>
        </li>
        <li>Please use your judgment when interpreting AI-generated responses.</li>
      </ul>
      <li><strong>User Interaction:</strong></li>
      <ul>
        <li>When interacting with our AI, remember that it is an automated system.</li>
        <li>Responses are based on data and algorithms, not human intuition.</li>
      </ul>
      <li><strong>Privacy and Data Handling:</strong></li>
      <ul>
        <li>We respect your privacy. Any data you provide is handled according to our privacy policy.</li>
        <li>Our AI does not retain personal information from interactions.</li>
      </ul>
      <li><strong>Feedback and Improvements:</strong></li>
      <ul>
        <li>Your feedback helps us improve! If you notice issues, please let us know.</li>
        <li>We continuously work to enhance accuracy and user experience.</li>
      </ul>
      <li><strong>Contact Information:</strong></li>
      <ul>
        <li>For questions or concerns, contact [customer support email/phone].</li>
      </ul>
    </ol>
    <p>Thank you for using our AI system! We appreciate your trust.</p>
<!--    <div>-->
<!--      <button mat-button [mat-dialog-close]="true">Agree</button>-->
<!--    </div>-->
    <div class="spacer">&nbsp;</div>
  </div>
</div>
