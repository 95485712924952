<article>
  <h1>Welcome to the Enverus Intelligence® Vault
    <button type="button" class="back" (click)="routeToDashboard()" matTooltip="Return to Dashboard">
      <mat-icon svgIcon="back"></mat-icon>
    </button>
  </h1>
  <p>Here, you’ll discover the rich archive of published content and stay updated on the latest material crafted by our Intelligence Team.</p>
  <p>The site is divided into two main segments. Firstly, the primary content area is dedicated to Intelligence Reports, providing valuable insights, calls to
    action, and comprehensive coverage of the energy industry. The second area comprises our Live Feed content, offering concise, focused coverage of
    specific activities in a timely manner. While reports, with their in-depth coverage, are published at a slower rate, averaging 15 per week, the Live Feed
    delivers a continuous stream of new, focused content, with an average of 20 or more stories per day.</p>
  <p>Moreover, we have enhanced the user experience by spotlighting specific high-value research reports above the reports section, making it easier for
    clients to locate this valuable content. Additionally, over the Live Feed content, we’ve integrated specific data widgets showcasing high-level information
    from Enverus’ datasets.</p>
  <h2>Finding Content</h2>
  <p>The Enverus Intelligence® Vault serves as the single, go-to location for all published Intelligence content. Therefore, the ability to find what you are
    looking for becomes vital. Read below for some tips on how to navigate the Intelligence Vault.</p>
  <h3>Search</h3>
  <p>The search function is a valuable tool for quickly finding relevant content. When using this function, an algorithm applies a weighting to the search
    to return results based on relevancy. This may cause more relevant, though older content to appear higher in the results. If all elements are equal,
    however, newer content will always be placed higher. This is the default state of the Vault, where elements are shown in date descending order until
    a search is applied.</p>
  <h3>Filtering</h3>
  <p>Through the use of various filter options, clients can quickly narrow down their focus on specific content flags. While results utilizing the Search function will return in order of relevancy, clients using the filter will see results returned
    in a date descending order.</p>
  <p>Clients can also string together various elements using different filter types to give greater focus to their search. Each filter option operates as an
    “AND” variable to the overall search. For example, if a client selects Play Fundamentals from REPORT SERIES and Permian from REGIONS, the site
    will filter down to only content that provides an exact match for both fields.</p>
  <p>Additionally, clients have the ability to have a little more flexibility in their filtering by utilizing the “ANY” toggle in their filter. While the different filter
    options still operate under an “AND” structure, clients can allow more possible matches using this function. To continue the example from above, a
    client could still be looking for Play Fundamentals but wish to expand their filter to allow for either Permian OR Mid-Continent regions through the
    use of the ANY toggle.</p>
  <p>If a client wishes, they may also save that filter for repeated use.</p>
  <h3>Bookmarks</h3>
  <p>If a client wants to save specific reports or articles, they can click the add to bookmark icon found either along the right-hand side of the summary
    page or grouped with the other page functions, like download or return, when viewing the content itself. To locate these specific, bookmarked
    content, a client will simply need to click the top-line bookmark tab found in the Reports section. Here a client will be able to view or remove any
    existing bookmarked files.</p>
  <h2>Saved Filters</h2>
  <p>Clients now have the ability to save their filtered searches for quick, easy, repeated use. Once created, with a simple click of the filter, a client can apply
    the saved criteria, share that filter with peers, and even set it as the default selection for viewing the Intelligence Vault.</p>
  <h3>Create</h3>
  <p>Once a client applies specific filtering options and decides this is something they wish to save, this can be done by simply adding a filter name in
    the Create/Update Saved Filter panel on the right side of the filtering dialog box and clicking SAVE.
    Once created, a client may also choose to make this filter set their DEFAULT view in the vault by clicking the pin icon.</p>
  <p>Note: There is sometimes a delay in the new filter showing in the Saved Filter panel. If this happens, close the dialog box and refresh the page. It
    should now show properly.</p>
  <h3>Modify</h3>
  <p>Saved Filters can be modified at any time, either as a temporary change or permanently added as part of the Saved Filter. If the change is intended
    to be added to an existing filter, this can be done by simply clicking the UPDATE button found at the top of the Saved Filter panel. Optionally, a new
    filter can be created using the existing filter as a base, allowing the client to have multiple filter states that fit their desired search by choosing the
    CREATE NEW button instead.</p>
  <h3>Share</h3>
  <p>Once a saved filter is created, it can be shared via email with others. This allows users to easily share the ability to drill down to specific areas of
    interest within the Intelligence Vault with their peers. To share a filter via email, simply click on the SHARE FILTER icon located next to the filter’s
    name, which will open a new dialog box. From here, enter the recipient’s email address and an optional message if you so choose and click Submit.
    Note: While the ability to share Saved Filters is not restricted, any access to content will still be limited to a user’s entitlement.</p>
  <h3>Setting up an Email Alert for Saved Filters</h3>
  <p>Clients can now easily set up email alerts for content based on their Saved Filters. To do so, click the EMAIL icon next to the filter name, triggering a
    new dialog box. Here, choose from Daily, Weekly, or Monthly options, or click NONE to cancel an existing alert.
    Once created, an email will be automatically generated, providing links to all content that matches the Saved Filter criteria.</p>
  <p>Note: While Saved Filters may contain search terms for more relevant results, they cannot be included as part of the Email Alert system at this time.
    Results will only be based on the filter options applied.</p>
  <p>Return to Dashboard
    <button type="button" class="back" (click)="routeToDashboard()" matTooltip="Return to Dashboard">
      <mat-icon svgIcon="back"></mat-icon>
    </button>
  </p>
</article>
