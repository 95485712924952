import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, Inject,
  Input, OnDestroy,
  OnInit,
  Output, PLATFORM_ID,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {CommonModule, isPlatformServer} from '@angular/common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import {Subject} from 'rxjs';

import {environment as ENV} from '../../../environments/environment';
import {UserService} from '../../services/user/user.service';
import {Core} from "@pdftron/webviewer";
import LayerContext = Core.Document.LayerContext;
import {InlineLoadingComponent} from "../inline-loading/inline-loading.component";

@Component({
  selector: 'iv-pdftron',
  standalone: true,
  imports: [
    CommonModule,
    InlineLoadingComponent
  ],
  templateUrl: './pdftron.component.html',
  styleUrls: ['./pdftron.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PdftronComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() document: string = '';
  @Input() options: any = {};
  @Output() pdfWordCount = new EventEmitter<string>();

  // webViewer: any;
  readonly disabledElements: any = [
    'menuButton',
    'downloadButton',
    'filePickerButton',
    'printButton',
    'saveAsButton',
    'settingsButton',
    'themeChangeButton',
    'fullscreenButton',

    'toolbarGroup-Shapes',
    'toolbarGroup-Edit',
    'toolbarGroup-Insert',
    'toolbarGroup-Annotate',
    'toolbarGroup-FillAndSign',
    'toolbarGroup-Forms',

    'notesPanelToggle',

    'outlinesPanel-tabPanel',
    'bookmarksPanel-tabPanel',
    'fileAttachmentPanel-tabPanel',
    'layersPanel-tabPanel',

    // 'linkAnnotationPopup',
    'linkAnnotationUnlinkButton',
  ];

  profile: any;
  preferences: any;

  @ViewChild('viewer') viewer!: ElementRef;
  isViewerLoaded = false;

  @Output() coreControlsEvent:EventEmitter<string> = new EventEmitter();

  private documentLoaded$: Subject<void> = new Subject<void>();

  isMobile: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private breakpointObserver: BreakpointObserver,
    private userService: UserService
  ) {
    this.profile = this.userService.getUserProfile();
    this.preferences = this.userService.getPreferences();

    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.isMobile = result.matches;
    });

  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {

    if(!isPlatformServer(this.platformId)) {
      this.initPdftronWebviewer();
    }

    setTimeout(() => {
      const viewerElement = document.querySelector('apryse-webviewer');
      if (viewerElement) {
        const outerShadowRoot = (viewerElement as any).shadowRoot;
        if (outerShadowRoot) {
          // const docElement = outerShadowRoot.querySelector('.document');
          const docElement = outerShadowRoot.querySelector('.document-content-container');
          if (docElement) {
            (docElement as HTMLElement).style.minHeight = '1024px';
            (docElement as HTMLElement).style.setProperty('min-height', '1024px', 'important');
          }
        }
      }
    }, 1000); // Give time for WebViewer to initialize

  }

  private async initPdftronWebviewer() {

    if(!isPlatformServer(this.platformId)) {
      const WebViewer = (await import('@pdftron/webviewer')).default;
      WebViewer({
        css: 'assets/pdftron-custom-css/main.css',
        path: 'assets/pdftron',
        // fullAPI: true,
        initialDoc: this.document,
        extension: 'pdf',
        licenseKey: atob(ENV.PDFTRON.key),
        enableMeasurement: true,
        disabledElements: this.disabledElements,
        // disableVirtualDisplayMode: true
      }, this.viewer.nativeElement).then(instance => {

        const {documentViewer} = instance.Core;

        documentViewer.addEventListener('documentLoaded', async () => {
          this.documentLoaded$.next();

          // load the UI data from a json file
          const response: Response = await fetch('/assets/pdftron-custom-css/pdftron-webviewer-ui.json');
          const uiData: any = await response.json();
          instance.UI.importModularComponents(uiData);

          const doc = documentViewer.getDocument();

          // disable security warning
          instance.Core.documentViewer.setOpenURIHandler((target) => {
            window.open(target, "_blank", "noopener");
          });

          // disable features
          instance.UI.disableFeatures([
            // instance.UI.Feature.Annotations,   // disabling annotations turns off view/measure menus
            instance.UI.Feature.Download,
            instance.UI.Feature.FilePicker,
            instance.UI.Feature.LocalStorage,
            instance.UI.Feature.Print,            // does work but CTRL-P then tries to print browser page
          ]);

          if(this.isMobile) {
            instance.UI.disableFeatures([
              instance.UI.Feature.Annotations,   // disabling annotations turns off view/measure menus
            ]);
          }

          // instance.UI.setLayoutMode(instance.UI.LayoutMode.Single);
          instance.UI.setLayoutMode(instance.UI.LayoutMode.Continuous);
          // instance.UI.setToolMode('Pan');

          instance.UI.setFitMode(instance.UI.FitMode.FitWidth);
          // instance.UI.setFitMode(instance.UI.FitMode.FitPage);

          instance.UI.setTheme(instance.UI.Theme.DARK);

          // only enable Left Panel -> Layers tab if the document contains layers (resolve async)
          doc.getLayersArray().then((layers: LayerContext[]) => {
            if (layers && layers.length) {
              instance.UI.enableElements(['layersPanel-tabPanel']);
            }
          });

          // extract document text so can estimate time to read
          const pageCount: number = documentViewer.getPageCount();
          let text: string = '';
          for (let i: number = 1; i <= pageCount; i++) {
            text = text + ' ' + await doc.loadPageText(i);
          }
          this.pdfWordCount.emit(text);

          // if url parameter ?pdfPage=# passed in, jump to that page
          if (this.options && this.options.page > 1) {

            const displayModeManager = documentViewer.getDisplayModeManager();
            const displayMode: any = displayModeManager.getDisplayMode();

            if(displayMode.mode === 'Single') {
              documentViewer.setCurrentPage(this.options.page, true);
              const el = document.getElementById('pdf-preview');
              if (el) {
                el.scrollIntoView();
              } else {
                document.getElementsByTagName('iv-research-detail')[0].scrollIntoView();
              }

            } else {

              setTimeout(() => {
                let scrollViewElement = documentViewer.getScrollViewElement();
                documentViewer.setCurrentPage(this.options.page, false);
                const pageScroll = scrollViewElement.scrollTop;
                scrollViewElement.scroll({
                  top: pageScroll,
                });
              }, 3000); // Adjust delay if needed to ensure pages have rendered.

              const el = document.getElementById('pdf-preview');
              if (el) {
                el.scrollIntoView();
              } else {
                document.getElementsByTagName('iv-research-detail')[0].scrollIntoView();
              }

            }

          }

        });

        // instance.UI.addEventListener(instance.UI.Events.VIEWER_LOADED, e => {
        //   setTimeout(() => {
        //     // instance.UI.setZoomLevel('200%');
        //     instance.UI.setFitMode(instance.UI.FitMode.FitWidth);
        //     this.isViewerLoaded = true;
        //   }, 2000); // Give time for WebViewer to initialize
        // });

        this.isViewerLoaded = true;


      });

    }

  }

  ngOnDestroy() {
  }

}
