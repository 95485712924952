import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from "@angular/material/dialog";

@Component({
  selector: 'iv-reports-help',
  standalone: true,
  imports: [CommonModule, MatDialogModule],
  templateUrl: './reports-help.component.html',
  styleUrls: ['./reports-help.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportsHelpComponent {

}
