import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OriginalUrlService} from "../../services/original-url/original-url.service";
import { Router } from '@angular/router';

@Injectable()
export class OriginalUrlInterceptor implements HttpInterceptor {
  constructor(private originalUrlService: OriginalUrlService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        error: (error) => {
          if (error instanceof HttpErrorResponse && error.status === 404) {
            const originalUrl: string = this.router.url;
            this.originalUrlService.setOriginalUrl(originalUrl);
          }
        }
      })
    );
  }
}
