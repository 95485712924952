import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonModule, Location, LocationStrategy} from '@angular/common';
import {ActivatedRoute, Router} from "@angular/router";
import {OriginalUrlService} from "../../services/original-url/original-url.service";
import {UserService} from "../../services/user/user.service";
import {Constants} from "../../constants/constants";

@Component({
  selector: 'iv-four-o-four-iaq-error-page',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './four-o-four-iaq-page.component.html',
  styleUrls: ['./four-o-four-iaq-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FourOFourIaqPageComponent implements OnInit {
  public attemptedUrl: string = '';
  iaqId: string | null = null;
  profile: any;
  public dateTimeNow: string = new Date().toISOString();

  constructor(private router: Router, private route: ActivatedRoute, private location: Location,
              private locationStrategy: LocationStrategy, private originalUrlService: OriginalUrlService,
              private userService: UserService) {
    this.profile = this.userService.getUserProfile();
  }

  ngOnInit(): void {
    this.iaqId = this.route.snapshot.paramMap.get(Constants.chatbotQuery);
    this.attemptedUrl = this.originalUrlService.getOriginalUrl();
  }

}
