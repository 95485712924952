import {Component, ElementRef, Inject, ViewChild, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {Clipboard} from '@angular/cdk/clipboard';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {DataService} from '../../services/data/data.service';
import {LogService} from '../../services/log/log.service';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';

@Component({
  selector: 'iv-share-filter-dialog',
  standalone: true,
  imports: [CommonModule, FormsModule, MatButtonModule, MatInputModule, ReactiveFormsModule, MatIconModule, MatTooltipModule],
  templateUrl: './share-filter-dialog.component.html',
  styleUrls: ['./share-filter-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShareFilterDialogComponent {
  shareForm = new FormGroup({
    emails: new FormControl(null, [
      Validators.required,
      Validators.pattern(/^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|[a-zA-Z0-9._%+-]+@[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+)(,\s*([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|[a-zA-Z0-9._%+-]+@[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+))*$/)
    ]),
    message: new FormControl(null)
  });

  error = false;
  success = false;
  emailList: any;

  @ViewChild('copyIcon', {read: ElementRef}) copyIcon: ElementRef | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ShareFilterDialogComponent>, private dataService: DataService, private logService: LogService, private clipboard: Clipboard) {
  }

  get f() {
    return this.shareForm.controls;
  }

  copyToClipboard() {
    if (this.copyIcon) {
      const originalIcon = this.copyIcon.nativeElement.textContent;
      this.copyIcon.nativeElement.textContent = 'check';
      this.clipboard.copy(this.data.url);

      this.logService.track("filter_url_shared", false,{
        filter_name: this.data.filter.title,
        filter_url: this.data.url
      });

      setTimeout(() => {
        if (this.copyIcon) {
          this.copyIcon.nativeElement.textContent = originalIcon;
        }
      }, 2000);
    }
  }

  onSubmit() {
    if (this.shareForm.valid) {
      this.emailList = this.shareForm.value.emails;
      const payload = {
        emails: this.shareForm.value.emails,
        message: this.shareForm.value.message,
        data: {title: this.data.filter.title, url: this.data.url}
      };
      this.dataService.shareFilter(payload).subscribe({
        next: (v) => {
          this.error = false;
          this.success = true;

          this.logService.track("filter_shared", false,{
            filter_name: this.data.filter.title,
            filter_url: this.data.url,
            sender_message: this.shareForm.value.message,
            recipient_email: this.emailList
          });

        },
        error: (e) => {
          this.success = false;
          this.error = true;
        }
      });
    }
  }

  closeShareDialog() {
    this.dialogRef.close();
  }
}
