<ng-container *ngIf="!dashboardService.initializing" id="dashboard">
  <div class="pane-selector">
    <button (click)="switchDashboardView($event)" *ngIf="class==='reports'">Go to Live Feed <mat-icon>arrow_forward</mat-icon></button>
    <button (click)="switchDashboardView($event)" *ngIf="class==='livefeed'">Go to Reports <mat-icon>arrow_forward</mat-icon></button>
  </div>
  <div class="box">
    <iv-research-spotlight></iv-research-spotlight>
  </div>
  <div class="box">
    <iv-widgets-area></iv-widgets-area>
  </div>
  <div class="box">
    <iv-reports-nav></iv-reports-nav>
  </div>
  <div class="box">
    <iv-livefeed-nav></iv-livefeed-nav>
  </div>
</ng-container>
